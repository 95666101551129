
import { onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import Icon from "../../../components/icon/index.vue";
import InputCounter from "../../../components/input-counter.vue";
import Navbar from "../../../components/navbar.vue";
import { useAvailabilityDAO } from "../../../entities/availability";

export default {
  name: "ManageAvailabilty",
  components: {
    Icon,
    InputCounter,
    Navbar,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const availability = useAvailabilityDAO();

    async function submit() {
      // await availabilityForm.createAvailability.execute();
      // router.push({
      //   name: "editTourPricing",
      //   params: { tourId: route.params.tourId },
      // });
    }

    onUnmounted(() => {
      availability.value.$reset();
    });

    return {
      route,
      availability,
      submit,
    };
  },
};
