export const TIMEZONES = {
  ATLANTIC_AZORES: "Atlantic/Azores",
  ATLANTIC_BERMUDA: "Atlantic/Bermuda",
  ATLANTIC_CANARY: "Atlantic/Canary",
  ATLANTIC_CAPE_VERDE: "Atlantic/Cape_Verde",
  ATLANTIC_FAEROE: "Atlantic/Faeroe",
  ATLANTIC_FAROE: "Atlantic/Faroe",
  ATLANTIC_JAN_MAYEN: "Atlantic/Jan_Mayen",
  ATLANTIC_MADEIRA: "Atlantic/Madeira",
  ATLANTIC_REYKJAVIK: "Atlantic/Reykjavik",
  ATLANTIC_SOUTH_GEORGIA: "Atlantic/South_Georgia",
  ATLANTIC_ST_HELENA: "Atlantic/St_Helena",
  ATLANTIC_STANLEY: "Atlantic/Stanley",
  EUROPE_AMSTERDAM: "Europe/Amsterdam",
  EUROPE_ANDORRA: "Europe/Andorra",
  EUROPE_ASTRAKHAN: "Europe/Astrakhan",
  EUROPE_ATHENS: "Europe/Athens",
  EUROPE_BELFAST: "Europe/Belfast",
  EUROPE_BELGRADE: "Europe/Belgrade",
  EUROPE_BERLIN: "Europe/Berlin",
  EUROPE_BRATISLAVA: "Europe/Bratislava",
  EUROPE_BRUSSELS: "Europe/Brussels",
  EUROPE_BUCHAREST: "Europe/Bucharest",
  EUROPE_BUDAPEST: "Europe/Budapest",
  EUROPE_BUSINGEN: "Europe/Busingen",
  EUROPE_CHISINAU: "Europe/Chisinau",
  EUROPE_COPENHAGEN: "Europe/Copenhagen",
  EUROPE_DUBLIN: "Europe/Dublin",
  EUROPE_GIBRALTAR: "Europe/Gibraltar",
  EUROPE_GUERNSEY: "Europe/Guernsey",
  EUROPE_HELSINKI: "Europe/Helsinki",
  EUROPE_ISLE_OF_MAN: "Europe/Isle_of_Man",
  EUROPE_ISTANBUL: "Europe/Istanbul",
  EUROPE_JERSEY: "Europe/Jersey",
  EUROPE_KALININGRAD: "Europe/Kaliningrad",
  EUROPE_KIEV: "Europe/Kiev",
  EUROPE_KIROV: "Europe/Kirov",
  EUROPE_LISBON: "Europe/Lisbon",
  EUROPE_LJUBLJANA: "Europe/Ljubljana",
  EUROPE_LONDON: "Europe/London",
  EUROPE_LUXEMBOURG: "Europe/Luxembourg",
  EUROPE_MADRID: "Europe/Madrid",
  EUROPE_MALTA: "Europe/Malta",
  EUROPE_MARIEHAMN: "Europe/Mariehamn",
  EUROPE_MINSK: "Europe/Minsk",
  EUROPE_MONACO: "Europe/Monaco",
  EUROPE_MOSCOW: "Europe/Moscow",
  EUROPE_NICOSIA: "Europe/Nicosia",
  EUROPE_OSLO: "Europe/Oslo",
  EUROPE_PARIS: "Europe/Paris",
  EUROPE_PODGORICA: "Europe/Podgorica",
  EUROPE_PRAGUE: "Europe/Prague",
  EUROPE_RIGA: "Europe/Riga",
  EUROPE_ROME: "Europe/Rome",
  EUROPE_SAMARA: "Europe/Samara",
  EUROPE_SAN_MARINO: "Europe/San_Marino",
  EUROPE_SARAJEVO: "Europe/Sarajevo",
  EUROPE_SARATOV: "Europe/Saratov",
  EUROPE_SIMFEROPOL: "Europe/Simferopol",
  EUROPE_SKOPJE: "Europe/Skopje",
  EUROPE_SOFIA: "Europe/Sofia",
  EUROPE_STOCKHOLM: "Europe/Stockholm",
  EUROPE_TALLINN: "Europe/Tallinn",
  EUROPE_TIRANE: "Europe/Tirane",
  EUROPE_TIRASPOL: "Europe/Tiraspol",
  EUROPE_ULYANOVSK: "Europe/Ulyanovsk",
  EUROPE_UZHGOROD: "Europe/Uzhgorod",
  EUROPE_VADUZ: "Europe/Vaduz",
  EUROPE_VATICAN: "Europe/Vatican",
  EUROPE_VIENNA: "Europe/Vienna",
  EUROPE_VILNIUS: "Europe/Vilnius",
  EUROPE_VOLGOGRAD: "Europe/Volgograd",
  EUROPE_WARSAW: "Europe/Warsaw",
  EUROPE_ZAGREB: "Europe/Zagreb",
  EUROPE_ZAPOROZHYE: "Europe/Zaporozhye",
  EUROPE_ZURICH: "Europe/Zurich",
  UTC: "UTC",
} as const;

export type Timezone = keyof typeof TIMEZONES;
