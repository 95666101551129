
import { ref } from "vue";
import Icon from "../components/icon/index.vue";
import { watch } from "vue";
import { toRefs } from "vue";

export default {
  name: "InputCounter",
  components: {
    Icon,
  },
  props: {
    modelValue: {
      type: Number,
    },
  },
  emits: ["update:modelValue"],
  setup(props, ctx) {
    const { modelValue } = toRefs(props);
    const value = ref(props.modelValue);
    watch(modelValue, (v) => {
      if (v !== value.value) {
        value.value = v;
      }
    });
    watch(value, (v) => ctx.emit("update:modelValue", v));

    const add = () => {
      value.value += 1;
    };
    const subtract = () => {
      value.value -= 1;
    };

    return {
      add,
      subtract,
      value,
    };
  },
};
