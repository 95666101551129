
import { ref, toRef, watch } from "vue";
import { Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import {
  useConfirmDialog,
  useVirtualList,
  useWindowSize,
  useScroll,
  useMousePressed,
} from "@vueuse/core";
import { FloatingPanel } from "vant";

import SearchPopup from "../popups/search.vue";
import FilterPopup from "../popups/filter.vue";
import Icon from "../components/icon/index.vue";
import Map from "../components/map/index.vue";
import Tour from "../components/tour.vue";
import SwiperControl from "../components/swiper-control.vue";
import { useTourResultsStore } from "../stores/tours";
import { Geolocation } from "@capacitor/geolocation";
import { useSearchStore } from "../stores/search";

export default {
  name: "Explore",
  components: {
    SearchPopup,
    FilterPopup,
    Icon,
    Map,
    Tour,
    SwiperControl,
    Swiper,
    SwiperSlide,
    FloatingPanel,
  },
  setup() {
    // TODO: move selectedTour, scrollPosition, resultPanelHeight to store
    const searchStore = useSearchStore();
    const selectedTour = ref(0);
    const tourResults = useTourResultsStore();
    const tours = toRef(tourResults, "tours");
    const { list, containerProps, wrapperProps } = useVirtualList(tours, {
      itemHeight: 120,
    });
    const { arrivedState } = useScroll(containerProps.ref);
    const { pressed } = useMousePressed();
    const { height } = useWindowSize();

    const contentDraggable = ref(true);

    watch(arrivedState, ({ top }) => {
      if (!top) {
        contentDraggable.value = false;
      } else {
        contentDraggable.value = !pressed;
      }
    });
    watch(pressed, () => {
      contentDraggable.value = arrivedState.top;
    });

    const resultPanelHeight = ref(0);
    const resultPanelAnchors = [
      0,
      Math.round(0.6 * height.value),
      height.value - 77 + 30, // 77px is the search input height and margin-top, 30px is the drag header height
    ];

    const openResultList = () => {
      resultPanelHeight.value = Math.round(0.6 * height.value);
    };

    const closeResultList = () => {
      resultPanelHeight.value = 0;
    };

    const filterDialog = useConfirmDialog();
    const searchDialog = useConfirmDialog();

    searchDialog.onConfirm(() => {
      openResultList();
    });

    Geolocation.getCurrentPosition().then((coordinates) => {
      searchStore.location = [
        coordinates.coords.longitude,
        coordinates.coords.latitude,
      ];
    });

    return {
      openResultList,
      closeResultList,
      searchStore,
      selectedTour,
      tours,
      Virtual,
      searchDialog,
      filterDialog,
      resultPanelHeight,
      resultPanelAnchors,
      list,
      containerProps,
      wrapperProps,
      contentDraggable,
    };
  },
};
