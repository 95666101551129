import { defineStore } from "pinia";
import { Ref, ref } from "vue";

// NOTE - differention between state of the search and state of the explore view
export const useSearchStore = defineStore("search", () => {
  const location: Ref<[number, number]> = ref();

  return {
    location,
  };
});
