
import { PropType } from "vue";
import { Image as Unpic } from "@unpic/vue";

const IMAGE_DIMENSIONS = {
  DESKTOP_FULL: {
    width: 1920,
    height: 1080,
  },
  DESKTOP_HALF_PANE: {
    width: 960,
    height: 1080,
  },
  MOBILE_FULL: {
    width: 430,
    height: 645,
  },
} as const;

export default {
  name: "Image",
  components: {
    Unpic,
  },
  props: {
    id: {
      type: String,
      default: undefined,
    },
    layout: {
      type: String as unknown as PropType<"constrained">,
      default: "constrained",
    },
    alt: {
      type: String,
      default: "",
    },
  },
  setup() {
    function transformer({ url, width }) {
      let height: number;

      switch (width) {
        case IMAGE_DIMENSIONS.DESKTOP_FULL.width:
          height = IMAGE_DIMENSIONS.DESKTOP_FULL.height;
          break;
        case IMAGE_DIMENSIONS.DESKTOP_HALF_PANE.width:
          height = IMAGE_DIMENSIONS.DESKTOP_HALF_PANE.height;
          break;
        case IMAGE_DIMENSIONS.MOBILE_FULL.width:
          height = IMAGE_DIMENSIONS.MOBILE_FULL.height;
          break;
      }

      const query = new URLSearchParams([
        ["width", width],
        ["height", height],
      ]).toString();

      return `${url}?${query}`;
    }

    return {
      transformer,
    };
  },
};
