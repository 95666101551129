
import { ref, watch } from "vue";
import { Popup } from "vant";
import { useConfirmDialog, watchDebounced } from "@vueuse/core";

import Icon from "../components/icon/index.vue";
import PopupHeader from "../components/popup/header.vue";
import SearchResult from "../components/search-result.vue";
import { search } from "../services";

export default {
  name: "GeoSearchPopup",
  components: {
    Icon,
    Popup,
    PopupHeader,
    SearchResult,
  },
  emits: ["cancel", "apply"],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    // TODO: watch only q
    const q = ref("");

    const geocodeResults = ref<
      Array<{
        title: string;
        subtitle: string;
        country: string;
        coordinates: {
          longitude: number;
          latitude: number;
        };
        bbox: Array<number>;
      }>
    >([]);

    watchDebounced(
      q,
      async (newQ) => {
        if (newQ) {
          const data = await search.geocode({ q: q.value });

          geocodeResults.value = data.features?.map((feature) => {
            const type = feature.properties.feature_type;
            const context = feature.properties.context;

            return {
              title: context[type].name,
              subtitle: context.district
                ? `${context.district.name}, ${context.region.name}, ${context.country.name}`
                : context.region
                  ? `${context.region.name}, ${context.country.name}`
                  : context.country.name,
              country: context.country.name,
              coordinates: {
                longitude: feature.properties.coordinates[0],
                latitude: feature.properties.coordinates[1],
              },
              bbox: feature.properties.bbox,
            };
          });
        }
      },
      { deep: true, debounce: 500, maxWait: 5000 },
    );

    return {
      q,
      geocodeResults,
    };
  },
};
