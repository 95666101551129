import * as VueRouter from "vue-router";
import Bookings from "./views/bookings.vue";
import Explore from "./views/explore.vue";
import Map from "./views/map.vue";
import FAQ from "./views/faq.vue";
import Login from "./views/auth/login/index.vue";
import LoginByEmail from "./views/auth/login/email.vue";
import ChangePassword from "./views/auth/password/change.vue";
import ChangeEmail from "./views/auth/email/change.vue";
import Profile from "./views/profile.vue";
import Invoices from "./views/invoices.vue";
import EditTouristProfile from "./views/profile/edit/tourist.vue";
import EditOperatorProfile from "./views/profile/edit/operator.vue";
import Register from "./views/auth/register/index.vue";
import RegisterByEmail from "./views/auth/register/email.vue";
import Favorites from "./views/favorites.vue";
import Tours from "./views/tours.vue";
import Venues from "./views/venues.vue";
import Tour from "./views/tour.vue";
import Commission from "./views/commission.vue";
import EditVenue from "./views/venue/create/index.vue";
import EditVenueOverview from "./views/venue/create/overview.vue";
import EditVenueDescription from "./views/venue/create/description.vue";
import EditVenuePhotos from "./views/venue/create/photos.vue";
import EditVenueAddress from "./views/venue/create/address.vue";
import EditTour from "./views/tour/create/index.vue";
import EditTourOverview from "./views/tour/create/overview.vue";
import EditTourDescription from "./views/tour/create/description.vue";
import EditTourPhotos from "./views/tour/create/photos.vue";
import EditTourVenue from "./views/tour/create/venue.vue";
// import EditTourItinerary from "./views/tour/create/itinerary.vue";
// import EditTourMeetingPoint from "./views/tour/create/meeting-point.vue";
import EditTourOccurrences from "./views/tour/create/occurrences.vue";
import EditTourOccurrence from "./views/tour/create/occurrence.vue";
import EditTourAvailability from "./views/tour/create/availability.vue";
import EditTourPricing from "./views/tour/create/pricing.vue";
import Availabilties from "./views/availabilities.vue";
import Availabilty from "./views/availability.vue";
import { useUserStore } from "./stores/user";
import { backend } from "./services";

function createRouter() {
  const user = useUserStore();

  const routes = [
    {
      path: "/",
      name: "explore",
      component: Explore,
      meta: { showBottomNav: true },
    },
    {
      path: "/map",
      name: "map",
      component: Map,
      meta: { showBottomNav: false },
    },
    {
      path: "/tours",
      name: "tours",
      component: Tours,
      meta: { showBottomNav: true },
    },
    {
      path: "/venues",
      name: "venues",
      component: Venues,
      meta: { showBottomNav: true },
    },
    {
      path: "/editor/venues/:venueId",
      component: EditVenue,
      meta: { showBottomNav: false },
      beforeEnter: () => {
        if (!user.isLoggedIn) return { name: "register" };
        return true;
      },
      children: [
        {
          path: "",
          name: "editVenueOverview",
          component: EditVenueOverview,
        },
        {
          path: "description",
          name: "editVenueDescription",
          component: EditVenueDescription,
        },
        {
          path: "photos",
          name: "editVenuePhotos",
          component: EditVenuePhotos,
        },
        {
          path: "address",
          name: "editVenueAddress",
          component: EditVenueAddress,
        },
      ],
    },
    {
      path: "/editor/tours/:tourId",
      component: EditTour,
      meta: { showBottomNav: false },
      beforeEnter: () => {
        if (!user.isLoggedIn) return { name: "register" };
        return true;
      },
      children: [
        {
          path: "",
          name: "editTourOverview",
          component: EditTourOverview,
        },
        {
          path: "description",
          name: "editTourDescription",
          component: EditTourDescription,
        },
        {
          path: "photos",
          name: "editTourPhotos",
          component: EditTourPhotos,
        },
        {
          path: "venue",
          name: "editTourVenue",
          component: EditTourVenue,
        },
        // {
        //   path: "itinerary",
        //   name: "editTourItinerary",
        //   component: EditTourItinerary,
        // },
        // {
        //   path: "meeting-point",
        //   name: "editTourMeetingPoint",
        //   component: EditTourMeetingPoint,
        // },
        {
          path: "occurrences",
          name: "editTourOccurrences",
          component: EditTourOccurrences,
        },
        {
          path: "occurrence",
          name: "editTourOccurrence",
          component: EditTourOccurrence,
        },
        {
          path: "availability",
          name: "editTourAvailability",
          component: EditTourAvailability,
        },
        {
          path: "pricing",
          name: "editTourPricing",
          component: EditTourPricing,
        },
      ],
    },
    {
      path: "/tours/:tourId",
      name: "tour",
      component: Tour,
      meta: { showBottomNav: false },
    },
    {
      path: "/tours/:tourId/availabilities",
      name: "availabilities",
      component: Availabilties,
      meta: { showBottomNav: false },
    },
    {
      path: "/tours/:tourId/availabilities/:availabilityId",
      name: "availability",
      component: Availabilty,
      meta: { showBottomNav: false },
    },
    {
      path: "/explore",
      name: "explore",
      component: Explore,
      meta: { showBottomNav: true },
    },
    {
      path: "/bookings",
      name: "bookings",
      component: Bookings,
      meta: { showBottomNav: true },
      beforeEnter: () => {
        if (!user.isLoggedIn) return { name: "register" };
        return true;
      },
    },
    {
      path: "/favorites",
      name: "favorites",
      component: Favorites,
      meta: { showBottomNav: true },
      beforeEnter: () => {
        if (!user.isLoggedIn) return { name: "register" };
        return true;
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      meta: { showBottomNav: true },
      beforeEnter: () => {
        if (!user.isLoggedIn) return { name: "register" };
        return true;
      },
    },
    {
      path: "/profile/edit",
      name: "edit-profile",
      component: EditTouristProfile,
      beforeEnter: () => {
        if (!user.isLoggedIn) return { name: "register" };
        if (user.type === "TOURIST") {
          return "/profile/edit/tourist";
        } else {
          return "/profile/edit/operator";
        }
      },
    },
    {
      path: "/profile/edit/tourist",
      name: "edit-tourist-profile",
      component: EditTouristProfile,
      meta: { showBottomNav: true },
      beforeEnter: () => {
        if (!user.isLoggedIn) return { name: "register" };
        return true;
      },
    },
    {
      path: "/profile/edit/operator",
      name: "edit-operator-profile",
      component: EditOperatorProfile,
      meta: { showBottomNav: true },
      beforeEnter: () => {
        if (!user.isLoggedIn) return { name: "register" };
        return true;
      },
    },
    {
      path: "/settings/commission",
      name: "commission",
      component: Commission,
      meta: { showBottomNav: false },
      beforeEnter: () => {
        if (!user.isLoggedIn) return { name: "register" };
        return true;
      },
    },
    {
      path: "/invoices",
      name: "invoices",
      component: Invoices,
      meta: { showBottomNav: false },
      beforeEnter: () => {
        if (!user.isLoggedIn) return { name: "register" };
        return true;
      },
    },
    {
      path: "/faq",
      name: "faq",
      component: FAQ,
      meta: { showBottomNav: false },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: { showBottomNav: true },
      beforeEnter: () => {
        if (user.isLoggedIn) return { name: "profile" };
        return true;
      },
    },
    {
      path: "/login/email",
      name: "loginByEmail",
      component: LoginByEmail,
      meta: { showBottomNav: true },
      beforeEnter: () => {
        if (user.isLoggedIn) return { name: "profile" };
        return true;
      },
    },
    {
      path: "/register",
      name: "register",
      component: Register,
      meta: { showBottomNav: true },
      beforeEnter: () => {
        if (user.isLoggedIn) return { name: "profile" };
        return true;
      },
    },
    {
      path: "/register/email",
      name: "registerByEmail",
      component: RegisterByEmail,
      meta: { showBottomNav: true },
      beforeEnter: () => {
        if (user.isLoggedIn) return { name: "profile" };
        return true;
      },
    },
    {
      path: "/password/change",
      name: "changePassword",
      component: ChangePassword,
      meta: { showBottomNav: false },
    },
    {
      path: "/email/change",
      name: "changeEmail",
      component: ChangeEmail,
      meta: { showBottomNav: false },
    },
  ];

  const router = VueRouter.createRouter({
    history: VueRouter.createWebHashHistory(),
    routes: routes as any,
  });

  router.beforeEach(async () => {
    if (user.isLoggedIn) return;

    try {
      const data = await backend.getMe({});
      user.$patch(data);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  });

  return router;
}

export default createRouter;
