import { Ref, ref, toRef } from "vue";
import { backend } from "../services";
import { useUserStore } from "../stores/user";
import { useSchedule } from "./schedule";

export function useSchedulesDAO() {
  const user = useUserStore();
  const accountId = toRef(user, "accountId");
  const _tourId: Ref<string> = ref("");
  const schedules = ref([]);

  async function $read(tourId: string) {
    _tourId.value = tourId;
    const { data } = await backend.getSchedules({
      accountId: accountId.value,
      tourId: _tourId.value,
    });
    schedules.value = data.map((s) => {
      const schedule = useSchedule();
      schedule.$fromDTO(s);
      return schedule;
    });
  }

  return {
    schedules,
    $read,
  };
}
