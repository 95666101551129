
import { ref, watch } from "vue";
import { Popup } from "vant";

import Icon from "../components/icon/index.vue";
import PopupHeader from "../components/popup/header.vue";
import SearchResult from "../components/search-result.vue";

export default {
  name: "TextSearchPopup",
  components: {
    Icon,
    Popup,
    PopupHeader,
    SearchResult,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["cancel", "apply"],
  setup() {
    const q = ref("");

    const results = ref<Array<string>>([]);

    watch(q, (newQ) => {
      if (newQ) {
        results.value = [newQ];
      } else {
        results.value = [];
      }
    });

    return {
      q,
      results,
    };
  },
};
