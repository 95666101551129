
import { onUnmounted, provide } from "vue";
import Icon from "../../../components/icon/index.vue";
import Navbar from "../../../components/navbar.vue";
import { useRoute } from "vue-router";
import { useTourDetailsDAO } from "../../../entities/tour-details";
import {
  occurrencesKey,
  schedulesKey,
  tourDetailsKey,
  tourImagesKey,
} from "../../../keys";
import { useUserStore } from "../../../stores/user";
import { useOccurrencesDAO } from "../../../entities/occurrences";
import { useSchedulesDAO } from "../../../entities/schedules";
import { useTourImagesDAO } from "../../../entities/tour-images";

export default {
  name: "TourWizard",
  components: {
    Icon,
    Navbar,
  },
  async setup() {
    const route = useRoute();
    const user = useUserStore();
    const tourDetails = useTourDetailsDAO();
    const tourImages = useTourImagesDAO();
    const schedules = useSchedulesDAO();
    const occurrences = useOccurrencesDAO();

    tourDetails.value.local.$patch({
      accountId: user.accountId,
      tourId: route.params.tourId as string,
    });

    tourImages.value.local.$patch({
      accountId: user.accountId,
      tourId: route.params.tourId as string,
    });

    onUnmounted(() => {
      tourDetails.value.local.$reset();
    });

    provide(tourDetailsKey, tourDetails);
    provide(tourImagesKey, tourImages);
    provide(schedulesKey, schedules.schedules);
    provide(occurrencesKey, occurrences.occurrences);

    await tourDetails.value.$findById().catch(() => {});
    await tourImages.value.$read().catch(() => {});
    await schedules.$read(route.params.tourId as string).catch(() => {});
    await occurrences.$read(route.params.tourId as string).catch(() => {});

    return {};
  },
};
