
import { Popup, TimePicker } from "vant";

import Icon from "../components/icon/index.vue";
import PopupHeader from "../components/popup/header.vue";
import { PropType, Ref, ref } from "vue";

export default {
  name: "TimePopup",
  components: {
    Icon,
    Popup,
    PopupHeader,
    TimePicker,
  },
  emits: ["cancel", "apply"],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    position: {
      type: String as PropType<"top" | "bottom">,
      default: "bottom",
    },
  },
  setup() {
    const selectedTime: Ref<[string, string]> = ref();

    return {
      selectedTime,
    };
  },
};
