import { ComputedRef, Ref, UnwrapRef, computed, ref, watchEffect } from "vue";
import { backend } from "../services";
import { components } from "@ing-tours/vue-backend-sdk/dist/openapi";

export type TourImages = {
  remote: ReturnType<typeof useTourImages>;
  local: ReturnType<typeof useTourImages>;
  $isFetching: ComputedRef<boolean>;
  $reset: () => void;
  $read: () => Promise<void>;
  $update: () => Promise<void>;
};

export function useTourImages() {
  const accountId: Ref<string> = ref("");
  const tourId: Ref<string> = ref("");
  const effectiveFrom: Ref<string> = ref("");
  const imageIds = ref<Array<string>>([]);

  const $body = computed(() => ({
    tourId: tourId.value,
    imageIds: imageIds.value,
  }));

  function $fromDTO(data: components["schemas"]["TourImagesResponse"]) {
    tourId.value = data.tourId;
    effectiveFrom.value = data.effectiveFrom;
    imageIds.value = data.imageIds;
  }

  function $patch(
    data?: Partial<{
      accountId: string;
      tourId: string;
      effectiveFrom: string;
      imageIds: Array<string>;
    }>,
  ) {
    if (data?.accountId) accountId.value = data.accountId;
    if (data?.tourId) tourId.value = data.tourId;
    if (data?.effectiveFrom) effectiveFrom.value = data.effectiveFrom;
    if (data?.imageIds) imageIds.value = data.imageIds;
  }

  function $reset() {
    $patch({
      accountId: "",
      tourId: "",
      effectiveFrom: "",
      imageIds: [],
    });
  }

  return {
    accountId,
    tourId,
    effectiveFrom,
    imageIds,
    $body,
    $fromDTO,
    $patch,
    $reset,
  };
}

export function useTourImagesDAO(): Ref<UnwrapRef<TourImages>> {
  const remote = useTourImages();
  const local = useTourImages();

  const $isFetching: ComputedRef<boolean> = computed(
    () => false, // TODO: _update.isFetching.value,
  );

  function $reset() {
    local.$patch(remote.$body.value);
  }

  async function $read() {
    const data = await backend.getTourImages({ tourId: local.tourId.value });
    remote.$patch(data);
  }

  async function $update() {
    const data = await backend.updateTourImages(
      { accountId: local.accountId.value, tourId: local.tourId.value },
      local.$body.value,
    );
    remote.$patch(data);
  }

  watchEffect(() => local.$patch(remote.$body.value));

  return ref({
    remote,
    local,
    $isFetching,
    $reset,
    $read,
    $update,
  });
}
