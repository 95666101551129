
import Navbar from "../../../components/navbar.vue";
import Icon from "../../../components/icon/index.vue";
import { ref } from "vue";

export default {
  name: "Register",
  components: {
    Navbar,
    Icon,
  },
  setup() {
    const accountType = ref("TOURIST");

    return {
      accountType,
    };
  },
};
