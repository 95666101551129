import { Ref, ref } from "vue";
import { backend } from "../services";
import { useOccurrence } from "./occurrence";

export function useOccurrencesDAO() {
  const _tourId: Ref<string> = ref("");
  const occurrences = ref([]);

  async function $read(tourId: string) {
    _tourId.value = tourId;
    const { data } = await backend.getOccurrences({
      tourId: _tourId.value,
    });
    occurrences.value = data.map((o) => {
      const occurrence = useOccurrence();
      occurrence.$fromDTO(o);
      return occurrence;
    });
  }

  return {
    occurrences,
    $read,
  };
}
