import { ComputedRef, Ref, computed, ref, watchEffect } from "vue";
import { backend } from "../services";
import { components } from "@ing-tours/vue-backend-sdk/dist/openapi";

export type Venue = {
  remote: ReturnType<typeof useVenue>;
  local: ReturnType<typeof useVenue>;
  $isFetching: ComputedRef<boolean>;
  $reset: () => void;
  $findById: () => Promise<void>;
  $read: (id: string) => Promise<void>;
  $create: () => Promise<void>;
};

export function useVenue() {
  const id: Ref<string> = ref("");
  const operatorId: Ref<string> = ref("");
  const imageIds: Ref<Array<string>> = ref([]);
  const language: Ref<string> = ref("");
  const title: Ref<string> = ref("");
  const summary: Ref<string> = ref("");
  const description: Ref<string> = ref("");

  const $body = computed(() => ({
    id: id.value,
    operatorId: operatorId.value,
    imageIds: imageIds.value,
    title: title.value,
    summary: summary.value,
    description: description.value,
  }));

  function $fromDTO(data: components["schemas"]["VenueResponse"]) {
    id.value = data.id;
    operatorId.value = data.operatorId;
    imageIds.value = data.imageIds;
    language.value = data.language;
    title.value = data.title;
    summary.value = data.summary;
    description.value = data.description;
  }

  function $patch(
    data?: Partial<{
      id: string;
      operatorId: string;
      imageIds: Array<string>;
      effectiveFrom: string;
      language: string;
      title: string;
      summary: string;
      description: string;
    }>,
  ) {
    if (data?.id) id.value = data.id;
    if (data?.operatorId) operatorId.value = data.operatorId;
    if (data?.imageIds) imageIds.value = data.imageIds;
    if (data?.language) language.value = data.language;
    if (data?.title) title.value = data.title;
    if (data?.summary) summary.value = data.summary;
    if (data?.description) description.value = data.description;
  }

  function $reset() {
    $patch({
      id: "",
      operatorId: "",
      imageIds: [],
      effectiveFrom: "",
      language: "",
      title: "",
      summary: "",
      description: "",
    });
  }

  return {
    id,
    operatorId,
    imageIds,
    language,
    title,
    summary,
    description,
    $body,
    $fromDTO,
    $patch,
    $reset,
  };
}

export function useVenueDAO() {
  const remote = useVenue();
  const local = useVenue();

  const $isFetching: ComputedRef<boolean> = computed(
    () => false, // TODO: _read.isFetching.value || _update.isFetching.value,
  );

  function $reset() {
    local.$patch(remote.$body.value);
  }

  async function $read(id: string) {
    local.id.value = id;
    const data = await backend.getVenueById({ venueId: local.id.value });
    remote.$patch(data);
  }

  async function $create() {
    const data = await backend.createVenue(
      { accountId: local.operatorId.value },
      {
        operatorId: local.operatorId.value,
      },
    );
    remote.$patch(data);
  }

  watchEffect(() => local.$patch(remote.$body.value));

  return ref({
    remote,
    local,
    $isFetching,
    $reset,
    $read,
    $create,
  });
}
