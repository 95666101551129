
import { useRoute } from "vue-router";
import { Swiper, SwiperSlide } from "swiper/vue";
import Venue from "../../../components/venue.vue";
import Map from "../../../components/map/index.vue";
import MapMarker from "../../../components/map/marker.vue";
import Navbar from "../../../components/navbar.vue";
import GeoSearchPopup from "../../../popups/geo-search.vue";
import Icon from "../../../components/icon/index.vue";
import { useConfirmDialog } from "@vueuse/core";

export default {
  name: "ManageAddress",
  components: {
    Navbar,
    GeoSearchPopup,
    Icon,
    Venue,
    Swiper,
    SwiperSlide,
    Map,
    MapMarker,
  },
  setup() {
    const route = useRoute();
    const geoSearchDialog = useConfirmDialog();

    return {
      route,
      geoSearchDialog,
    };
  },
};
