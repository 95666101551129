import { ComputedRef, Ref, UnwrapRef, computed, ref, watchEffect } from "vue";
import { backend } from "../services";

export type UserProfile = {
  remote: ReturnType<typeof useUserProfile>;
  local: ReturnType<typeof useUserProfile>;
  $isFetching: ComputedRef<boolean>;
  $reset: () => void;
  $read: (id: string) => Promise<void>;
  $update: () => Promise<void>;
};

export function useUserProfile() {
  const userId: Ref<string> = ref("");
  const givenName: Ref<string> = ref("");
  const familyName: Ref<string> = ref("");

  const fullName: ComputedRef<string> = computed(() => {
    return `${givenName.value} ${familyName.value}`;
  });

  const $body = computed(() => ({
    givenName: givenName.value,
    familyName: familyName.value,
  }));

  function $patch(
    data?: Partial<{
      userId: string;
      givenName: string;
      familyName: string;
    }>,
  ) {
    if (data?.userId) userId.value = data.userId;
    if (data?.givenName) givenName.value = data.givenName;
    if (data?.familyName) familyName.value = data.familyName;
  }

  function $reset() {
    $patch({
      userId: "",
      givenName: "",
      familyName: "",
    });
  }

  return {
    userId,
    givenName,
    familyName,
    fullName,
    $body,
    $patch,
    $reset,
  };
}

export function useUserProfileDAO(): Ref<UnwrapRef<UserProfile>> {
  const remote = useUserProfile();
  const local = useUserProfile();

  const $isFetching: ComputedRef<boolean> = computed(
    () => false, // TODO: _read.isFetching.value || _update.isFetching.value,
  );

  function $reset() {
    local.$patch(remote.$body.value);
  }

  async function $read(id: string) {
    local.userId.value = id;
    const data = await backend.getUserProfileByUserId({
      userId: local.userId.value,
    });
    remote.$patch(data);
  }

  async function $update() {
    const data = await backend.updateUserProfileByUserId(
      { userId: local.userId.value },
      local.$body.value,
    );
    remote.$patch(data);
  }

  watchEffect(() => local.$patch(remote.$body.value));

  return ref({
    remote,
    local,
    $isFetching,
    $reset,
    $read,
    $update,
  });
}
