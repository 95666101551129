import { Ref, ref, toRef } from "vue";
import { backend, search } from "../services";
import { useUserStore } from "../stores/user";
import { useVenue } from "./venue";

export function useVenuesDAO() {
  const user = useUserStore();
  const accountId = toRef(user, "accountId");
  const venues = ref([]);

  const q = ref("");
  const when = ref([]);

  async function $read() {
    const { data } = await backend.getVenues({ accountId: accountId.value });
    venues.value = data.map((o) => {
      const venue = useVenue();
      venue.$fromDTO(o);
      return venue;
    });
  }

  async function $find(query: { q: Ref<string>; when: Ref<Array<number>> }) {
    q.value = query.q.value;
    when.value = query.when.value;
    const data = await search.searchTours({ q: q.value, when: when.value });
    venues.value = data.hits.map(({ document }) => {
      const venue = useVenue();
      venue.$fromDTO({
        ...document,
        effectiveFrom: document.effectiveFrom.toString(),
      });
      return venue;
    });
  }

  return {
    venues,
    $read,
    $find,
  };
}
