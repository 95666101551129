
import Icon from "../icon/index.vue";

export default {
  name: "PopupHeader",
  emits: ["cancel"],
  props: {
    title: {
      type: String,
      required: true,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    showBackIcon: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Icon,
  },
};
