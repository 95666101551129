
import { Popup } from "vant";
import { useConfirmDialog } from "@vueuse/core";

import GeoSearchPopup from "../popups/geo-search.vue";
import TextSearchPopup from "../popups/text-search.vue";
import CalendarPopup from "../popups/calendar.vue";
import PopupHeader from "../components/popup/header.vue";
import { ref } from "vue";
import { useDate } from "../composables/date";
import { computed } from "vue";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import { useTourResultsStore } from "../stores/tours";

dayjs.extend(calendar);

export default {
  name: "SearchPopup",
  components: {
    GeoSearchPopup,
    TextSearchPopup,
    CalendarPopup,
    Popup,
    PopupHeader,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["cancel", "select"],
  setup(_, context) {
    const tourResults = useTourResultsStore();

    const searchText = ref("");

    const location = ref<{
      title: string;
      subtitle: string;
      country: string;
      location: {
        longitude: number;
        latitude: number;
      };
      bbox: [number, number, number, number];
    }>();

    const dateRangeFrom = useDate();
    const dateRangeTo = useDate();
    const dateRange = computed(() => {
      let dates = [];
      if (dateRangeFrom.dayjs.value && dateRangeTo.dayjs.value) {
        let date = dateRangeFrom.dayjs.value;

        while (date.isBefore(dateRangeTo.dayjs.value)) {
          dates.push(date.unix());
          date = dayjs(date).add(1, "d");
        }
      }

      return dates;
    });

    const searchDialog = useConfirmDialog();
    const geoSearchDialog = useConfirmDialog();
    const textSearchDialog = useConfirmDialog();
    const calendarDialog = useConfirmDialog();

    textSearchDialog.onConfirm((data) => {
      searchText.value = data;
    });

    geoSearchDialog.onConfirm((data) => {
      location.value = data;
    });

    calendarDialog.onConfirm((data) => {
      dateRangeFrom.$patch(data.selectedFrom.value);
      dateRangeTo.$patch(data.selectedTo.value);
    });

    const dateRangeDisplayValue = computed(() => {
      if (!dateRangeFrom.dayjs.value || !dateRangeTo.dayjs.value)
        return "Today";

      const from = dateRangeFrom.dayjs.value.calendar(dayjs(), {
        sameDay: "[Today]",
        nextDay: "[Tomorrow]",
        nextWeek: "dddd",
        lastDay: "[Yesterday]",
        lastWeek: "[Last] dddd",
        sameElse: "DD/MM/YYYY",
      });

      const to = dateRangeTo.dayjs.value.calendar(dayjs(), {
        sameDay: "[Today]",
        nextDay: "[Tomorrow]",
        nextWeek: "dddd",
        lastDay: "[Yesterday]",
        lastWeek: "[Last] dddd",
        sameElse: "DD/MM/YYYY",
      });

      return `${from} to ${to}`;
    });

    async function submit() {
      await tourResults.$find({ q: searchText, when: dateRange });
      context.emit("select");
    }

    return {
      searchDialog,
      geoSearchDialog,
      textSearchDialog,
      calendarDialog,
      searchText,
      dateRangeFrom,
      dateRangeTo,
      dateRange,
      dateRangeDisplayValue,
      location,
      submit,
    };
  },
};
