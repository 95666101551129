
import { PropType } from "vue";
import Rating from "./rating.vue";
import Image from "./image.vue";

export default {
  name: "Venue",
  components: {
    Image,
    Rating,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    imageId: {
      type: String,
      required: false,
      default: undefined,
    },
    direction: {
      type: String as PropType<"vertical" | "horizontal">,
      default: "horizontal",
    },
  },
};
