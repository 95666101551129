
import { useRoute, useRouter } from "vue-router";
import Icon from "../../../components/icon/index.vue";
import Navbar from "../../../components/navbar.vue";
import PhotoManager from "../../../components/photo-manager.vue";
import { useUserStore } from "../../../stores/user";
import { inject } from "vue";
import { tourImagesKey } from "../../../keys";

export default {
  name: "ManagePhotos",
  components: {
    Icon,
    Navbar,
    PhotoManager,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const user = useUserStore();
    const tourImages = inject(tourImagesKey);

    async function submit() {
      await tourImages.value.$update();
      router.push({
        name: "editTourVenue",
        params: { tourId: route.params.tourId },
      });
    }

    return {
      route,
      user,
      tourImages,
      submit,
    };
  },
};
