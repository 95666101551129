
import { useVirtualList } from "@vueuse/core";

import Icon from "../components/icon/index.vue";
import Navbar from "../components/navbar.vue";
import Tour from "../components/tour.vue";
import { useRouter } from "vue-router";
import { useToursDAO } from "../entities/tours";
import { useTourDAO } from "../entities/tour";
import { useUserStore } from "../stores/user";

export default {
  name: "Tours",
  components: {
    Icon,
    Navbar,
    Tour,
  },
  async setup() {
    const user = useUserStore();
    const router = useRouter();
    const tour = useTourDAO();
    const tours = useToursDAO();

    tour.value.local.operatorId = user.accountId;

    const { list, containerProps, wrapperProps } = useVirtualList(tours.tours, {
      itemHeight: 120,
    });

    tours.$read();

    async function createTour() {
      await tour.value.$create();
      router.push(`/editor/tours/${tour.value.remote.id}`);
    }

    return {
      createTour,
      tours,
      list,
      containerProps,
      wrapperProps,
    };
  },
};
