
import { Map, AttributionControl, Marker, Popup } from "maplibre-gl";
import {
  shallowRef,
  onMounted,
  onUnmounted,
  markRaw,
  ref,
  watch,
  toRef,
} from "vue";
import MapMarker from "./marker.vue";
import Icon from "../icon/index.vue";
import { PropType } from "vue";

export default {
  name: "Map",
  components: {
    MapMarker,
    Icon,
  },
  props: {
    center: {
      type: Array as unknown as PropType<[number, number]>,
      default: () => [139.753, 35.6844],
    },
    selected: {
      type: Number,
      default: undefined,
      validator(value) {
        return typeof value === "number" && Number.isInteger(value);
      },
    },
    locations: {
      type: Array<[number, number]>,
      default: [],
      validator(value) {
        return (
          Array.isArray(value) &&
          value.every(
            (v) =>
              Array.isArray(v) &&
              v.length === 2 &&
              v.every((i) => typeof i === "number"),
          )
        );
      },
    },
  },
  setup(props) {
    const mapContainer = shallowRef(null);
    const map = shallowRef(null);
    const markers = ref(null);

    const center = toRef(props, "center");

    watch(center, (newCenter) => {
      map.value?.easeTo({ center: newCenter });
    });

    const selected = toRef(props, "selected");

    watch(selected, (newSelected) => {
      map.value?.easeTo({ center: props.locations[newSelected] });
    });

    onMounted(() => {
      const apiKey = "NMrRi2Kl0sezO6nnwIWV";

      map.value = markRaw(
        new Map({
          container: mapContainer.value,
          style: `https://api.maptiler.com/maps/08212b12-91bf-4955-aa0e-f00fac4256f1/style.json?key=${apiKey}`,
          center: props.center,
          zoom: 11,
          attributionControl: false,
        }),
      );

      map.value.addControl(
        new AttributionControl({
          compact: false,
        }),
        "bottom-left",
      );

      map.value.on("load", async () => {
        const data = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [9.989216, 53.5539],
              },
              properties: {
                tsunami: true,
                mag: 1.5,
              },
            },
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [9.989216, 53.5538],
              },
              properties: {
                tsunami: true,
                mag: 1.5,
              },
            },
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [9.989116, 53.5539],
              },
              properties: {
                tsunami: true,
                mag: 1.5,
              },
            },
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [9.989314, 53.5539],
              },
              properties: {
                tsunami: true,
                mag: 1.5,
              },
            },
          ],
        };

        map.value.addSource("results", {
          type: "geojson",
          data,
          cluster: true,
          clusterMaxZoom: 14, // Max zoom to cluster points on
          clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        });

        map.value.addLayer({
          id: "clusters",
          type: "circle",
          source: "results",
          filter: ["has", "point_count"],
          paint: {
            // Use step expressions (https://maplibre.org/maplibre-style-spec/#expressions-step)
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 100
            //   * Yellow, 30px circles when point count is between 100 and 750
            //   * Pink, 40px circles when point count is greater than or equal to 750
            "circle-color": [
              "step",
              ["get", "point_count"],
              "#51bbd6",
              100,
              "#f1f075",
              750,
              "#f28cb1",
            ],
            "circle-radius": [
              "step",
              ["get", "point_count"],
              20,
              100,
              30,
              750,
              40,
            ],
          },
        });

        map.value.addLayer({
          id: "cluster-count",
          type: "symbol",
          source: "results",
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12,
          },
        });

        map.value.addLayer({
          id: "unclustered-results",
          type: "circle",
          source: "results",
          filter: ["!", ["has", "point_count"]],
          paint: {
            "circle-color": "#11b4da",
            "circle-radius": 4,
            "circle-stroke-width": 1,
            "circle-stroke-color": "#fff",
          },
        });

        // inspect a cluster on click
        map.value.on("click", "clusters", async (e) => {
          const features = map.value.queryRenderedFeatures(e.point, {
            layers: ["clusters"],
          });
          const clusterId = features[0].properties.cluster_id;
          const zoom = await map.value
            .getSource("results")
            .getClusterExpansionZoom(clusterId);
          map.value.easeTo({
            center: features[0].geometry.coordinates,
            zoom,
          });
        });

        // When a click event occurs on a feature in
        // the unclustered-point layer, open a popup at
        // the location of the feature, with
        // description HTML from its properties.
        map.value.on("click", "unclustered-results", (e) => {
          const coordinates = e.features[0].geometry.coordinates.slice();
          const mag = e.features[0].properties.mag;
          let tsunami;

          if (e.features[0].properties.tsunami === 1) {
            tsunami = "yes";
          } else {
            tsunami = "no";
          }

          // Ensure that if the map is zoomed out such that
          // multiple copies of the feature are visible, the
          // popup appears over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          new Popup()
            .setLngLat(coordinates)
            .setHTML(`magnitude: ${mag}<br>Was there a tsunami?: ${tsunami}`)
            .addTo(map.value);
        });

        map.value.on("mouseenter", "clusters", () => {
          map.value.getCanvas().style.cursor = "pointer";
        });
        map.value.on("mouseleave", "clusters", () => {
          map.value.getCanvas().style.cursor = "";
        });

        map.value.on("mouseenter", "unclustered-results", () => {
          map.value.getCanvas().style.cursor = "pointer";
        });
        map.value.on("mouseleave", "unclustered-results", () => {
          map.value.getCanvas().style.cursor = "";
        });

        // window.setInterval(() => {
        //   fetch('https://www.random.org/decimal-fractions/?num=2&dec=10&col=1&format=plain&rnd=new')
        //     .then(r => r.text())
        //     .then(text => {
        //       // Takes the two random numbers between 0 and 1 and converts them to degrees
        //       const coordinates = text.split('\n').map(l => (Number(l) * 180) - 90);
        //       const json = {
        //         type: 'Feature',
        //         geometry: {
        //           type: 'Point',
        //           coordinates
        //         }
        //       };
        //       // Update the drone symbol's location on the map
        //       map.value.getSource('results').setData(json);

        //       // Fly the map to the drone's current location
        //       map.value.flyTo({
        //         center: json.geometry.coordinates,
        //         speed: 0.5
        //       });
        //     });
        // }, 2000)
      });

      // add marker to map
      for (const i in props.locations) {
        new Marker({ element: markers.value[i].$el })
          .setLngLat(props.locations[i])
          .addTo(map.value);
      }
    });

    onUnmounted(() => {
      map.value?.remove();
    });

    return {
      map,
      mapContainer,
      locations: props.locations,
      markers,
    };
  },
};
