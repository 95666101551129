
import { inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import Icon from "../../../components/icon/index.vue";
import Navbar from "../../../components/navbar.vue";
import { useUserStore } from "../../../stores/user";
import { tourDetailsKey } from "../../../keys";

export default {
  name: "CreateDescription",
  components: {
    Icon,
    Navbar,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const tourDetails = inject(tourDetailsKey);

    async function submit() {
      await tourDetails.value.$create();
      router.push({
        name: "editTourPhotos",
        params: { tourId: route.params.tourId },
      });
    }

    return {
      route,
      submit,
      tourDetails,
    };
  },
};
