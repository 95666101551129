
import { Ref, ref } from "vue";
import CalendarMonth from "./month.vue";
import dayjs from "dayjs";

export default {
  name: "Calendar",
  emits: ["select"],
  props: {
    displayWeekdayHeader: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    CalendarMonth,
  },
  setup(_, context) {
    const months = Array(12)
      .fill(undefined)
      .map((_, i) => dayjs().add(i, "month"));
    const selectedFrom: Ref<[number, number, number] | undefined> = ref();
    const selectedTo: Ref<[number, number, number] | undefined> = ref();

    function select(index: [number, number, number]) {
      if (!selectedFrom.value) {
        selectedFrom.value = index;
      } else if (!selectedTo.value) {
        if (
          selectedFrom.value[0] > index[0] ||
          (selectedFrom.value[0] === index[0] &&
            selectedFrom.value[1] > index[1]) ||
          (selectedFrom.value[0] === index[0] &&
            selectedFrom.value[1] === index[1] &&
            selectedFrom.value[2] > index[2])
        ) {
          selectedTo.value = selectedFrom.value;
          selectedFrom.value = index;
        } else {
          selectedTo.value = index;
        }
      } else {
        selectedTo.value = undefined;
        selectedFrom.value = index;
      }

      context.emit("select", { selectedFrom, selectedTo });
    }

    return {
      months,
      selectedFrom,
      selectedTo,
      select,
    };
  },
};
