import dayjs, { Dayjs } from "dayjs";
import { ComputedRef, Ref, computed, ref } from "vue";

export function useDate() {
  const parts: Ref<[number, number, number]> = ref();

  const _dayjs = computed(() => {
    if (!parts.value) return undefined;

    return dayjs({
      year: parts.value[0],
      month: parts.value[1],
      day: parts.value[2],
      hour: 0,
      minute: 0,
      second: 0,
    });
  });

  const display: ComputedRef<string> = computed(() => {
    return _dayjs.value ? _dayjs.value.format("LL") : "";
  });

  function $patch(d?: Dayjs | [number, number, number]) {
    console.log(d, (d as any).value, typeof d);
    if (d && Array.isArray(d)) {
      parts.value = d;
    } else if (dayjs.isDayjs(d)) {
      parts.value = [d.year(), d.month(), d.day()];
    } else {
      parts.value = undefined;
    }
  }

  return {
    parts,
    display,
    dayjs: _dayjs,
    $patch,
  };
}
