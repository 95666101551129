
import { ref } from "vue";
import BottomNav from "./components/bottom-nav/index.vue";
import Navbar from "./components/navbar.vue";
import { useUserStore } from "./stores/user";
import { Notify } from "vant";

export default {
  name: "App",
  components: {
    BottomNav,
    Navbar,
    Notify,
  },
  setup() {
    const show = ref(false);

    return {
      show,
      user: useUserStore(),
    };
  },
};
