
import Icon from "../components/icon/index.vue";
import Navbar from "../components/navbar.vue";
import Tour from "../components/tour.vue";
import Rating from "../components/rating.vue";
import { showNotify } from "vant";

export default {
  name: "Availability",
  components: {
    Icon,
    Navbar,
    Tour,
    Rating,
  },
  setup() {
    const sendBookingRequest = () => {
      showNotify({ type: "success", message: "Booking request sent" });
      return true;
    };

    return {
      sendBookingRequest,
    };
  },
};
