
import Icon from "../../../components/icon/index.vue";
import Navbar from "../../../components/navbar.vue";
import { useConfirmDialog } from "@vueuse/core";
import { ref } from "vue";
import GeoSearchPopup from "../../../popups/geo-search.vue";

export default {
  name: "EditProfile",
  components: {
    Icon,
    Navbar,
    GeoSearchPopup,
  },
  setup() {
    const location = ref<{
      title: string;
      subtitle: string;
      country: string;
      location: {
        longitude: number;
        latitude: number;
      };
      bbox: [number, number, number, number];
    }>();

    const geoSearchDialog = useConfirmDialog();
    geoSearchDialog.onConfirm((data) => {
      location.value = data;
    });

    return {
      location,
      geoSearchDialog,
    };
  },
};
