import { createApp } from "vue";
import { createPinia } from "pinia";
import PortalVue from "portal-vue";

import "./style.css";
import "swiper/css";
import "swiper/css/pagination";
import "vant/es/style/base.css";
import "vant/es/notify/style/index.mjs";
import "vant/es/overlay/style/index.mjs";
import "vant/es/popup/style/index.mjs";
import "vant/es/sticky/style/index.mjs";
import "vant/es/floating-panel/style/index.mjs";
import "vant/es/slider/style/index.mjs";
import "vant/es/time-picker/style/index.mjs";
import "vant/es/password-input/style/index.mjs";

import createRouter from "./router";
import App from "./app.vue";

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(createRouter());
app.use(PortalVue);
app.mount("#app");
