
import { Popup } from "vant";

import Icon from "../components/icon/index.vue";
import PopupHeader from "../components/popup/header.vue";
import { PropType } from "vue";
import dayjs from "dayjs";

export default {
  name: "SelectPopup",
  components: {
    Icon,
    Popup,
    PopupHeader,
  },
  emits: ["cancel", "apply"],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    position: {
      type: String as PropType<"top" | "bottom">,
      default: "bottom",
    },
  },
  setup() {
    const availabilities = [
      {
        start: dayjs("2023-10-27"),
      },
      {
        start: dayjs("2023-10-17"),
      },
      {
        start: dayjs("2023-10-20"),
      },
    ];

    return {
      availabilities,
    };
  },
};
