
import dayjs from "dayjs";

export default {
  name: "WeekdayHeader",
  setup() {
    const weekdays = dayjs.weekdaysShort(); // can also be .weekdays() or .weekdaysMin()

    return { weekdays };
  },
};
