
import Icon from "../../../components/icon/index.vue";
import Navbar from "../../../components/navbar.vue";
import PasswordInput from "../../../components/inputs/password.vue";
import { ref } from "vue";

export default {
  name: "LoginByEmail",
  components: {
    Icon,
    Navbar,
    PasswordInput,
  },
  setup() {
    const form = ref({
      password: "",
    });

    return {
      form,
    };
  },
};
