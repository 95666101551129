import { ComputedRef, Ref, UnwrapRef, computed, ref, watchEffect } from "vue";
import { backend } from "../services";

export type Image = {
  type: "image";
  remote: ReturnType<typeof useImage>;
  local: ReturnType<typeof useImage>;
  $isFetching: ComputedRef<boolean>;
  $reset: () => void;
  $create: () => Promise<void>;
};

export function useImage() {
  const id: Ref<string> = ref("");
  const accountId: Ref<string> = ref("");
  const uploadId: Ref<string> = ref("");

  const $body = computed(() => ({
    uploadId: uploadId.value,
  }));

  function $patch(
    data?: Partial<{
      id: string;
      accountId: string;
      uploadId: string;
    }>,
  ) {
    if (data?.id) id.value = data.id;
    if (data?.accountId) accountId.value = data.accountId;
    if (data?.uploadId) uploadId.value = data.uploadId;
  }

  function $reset() {
    $patch({
      id: "",
      accountId: "",
      uploadId: "",
    });
  }

  return {
    id,
    accountId,
    uploadId,
    $body,
    $patch,
    $reset,
  };
}

export function useImageDAO(): Ref<UnwrapRef<Image>> {
  const remote = useImage();
  const local = useImage();

  const $isFetching: ComputedRef<boolean> = computed(
    () => false, // TODO: _create.isFetching.value,
  );

  function $reset() {
    local.$patch(remote.$body.value);
  }

  async function $create() {
    const data = await backend.createImage(
      { accountId: local.accountId.value },
      local.$body.value,
    );
    remote.$patch(data);
  }

  watchEffect(() => local.$patch(remote.$body.value));

  return ref({
    type: "image" as const,
    remote,
    local,
    $isFetching,
    $reset,
    $create,
  });
}
