import dayjs, { Dayjs } from "dayjs";
import { ComputedRef, Ref, computed, ref } from "vue";

export function useTime() {
  const parts: Ref<[string, string]> = ref();

  const _dayjs = computed(() => {
    if (!parts.value) return undefined;

    return dayjs({
      hour: parts.value[0],
      minute: parts.value[1],
      second: 0,
    });
  });

  const display: ComputedRef<string> = computed(() => {
    return _dayjs.value ? _dayjs.value.format("LT") : "";
  });

  function $patch(d?: Dayjs) {
    if (d) {
      parts.value = [d.hour().toString(), d.minute().toString()];
    } else {
      parts.value = undefined;
    }
  }

  return {
    parts,
    display,
    $patch,
  };
}
