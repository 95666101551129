
import Icon from "../../../components/icon/index.vue";
import Navbar from "../../../components/navbar.vue";
import OTPPopup from "../../../popups/otp.vue";
import { useConfirmDialog } from "@vueuse/core";
import { ref } from "vue";

export default {
  name: "ChangeEmail",
  components: {
    Icon,
    Navbar,
    OTPPopup,
  },
  setup() {
    const email = ref();
    const otpDialog = useConfirmDialog();

    return {
      email,
      otpDialog,
    };
  },
};
