import {
  ComputedRef,
  Ref,
  UnwrapRef,
  computed,
  ref,
  toRef,
  watchEffect,
} from "vue";
import { backend } from "../services";
import { RRule } from "rrule";
import dayjs, { Dayjs } from "dayjs";
import objectSupport from "dayjs/plugin/objectSupport";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useUserStore } from "../stores/user";
import { Timezone } from "../constants/Timezone";
import { components } from "@ing-tours/vue-backend-sdk/dist/openapi";
import { Frequency } from "../constants/Frequency";
import { useDateTime } from "../composables/date-time";

dayjs.extend(objectSupport);
dayjs.extend(LocalizedFormat);
dayjs.extend(duration);
dayjs.extend(relativeTime);

export type Schedule = {
  remote: ReturnType<typeof useSchedule>;
  local: ReturnType<typeof useSchedule>;
  $isFetching: ComputedRef<boolean>;
  $reset: () => void;
  //   $read: () => Promise<void>;
  $create: () => Promise<void>;
  //   $update: () => Promise<void>;
};

export function useSchedule() {
  const id: Ref<string> = ref("");
  const effectiveFrom: Ref<dayjs.Dayjs> = ref(undefined);
  const tourId: Ref<string> = ref("");
  const dtStart = useDateTime();
  const dtEnd = useDateTime();
  const tzid: Ref<Timezone> = ref("EUROPE_BERLIN");
  const freq: Ref<Frequency> = ref(undefined);
  const interval: Ref<number> = ref(undefined);
  const byweekday: Ref<Array<number>> = ref([]);
  const bymonth: Ref<Array<number>> = ref([]);
  const count: Ref<number> = ref(undefined);
  const untilDate: Ref<[number, number, number]> = ref();

  const untilDateDisplayValue: ComputedRef<string> = computed(() => {
    return untilDate.value ? dayjs(untilDate.value.join("-")).format("LL") : "";
  });

  const until = computed(() => {
    if (!untilDate.value) return undefined;

    return dayjs({
      year: untilDate.value[0],
      month: untilDate.value[1],
      day: untilDate.value[2],
      hour: 0,
      minute: 0,
      second: 0,
    });
  });

  const rrule: ComputedRef<RRule> = computed(() => {
    if (!dtStart.dayjs.value || !dtEnd.dayjs.value) return undefined;

    return new RRule({
      dtstart: dtStart.dayjs.value.toDate(),
      freq: RRule[freq.value],
      interval: interval.value,
      count: count.value,
      until: until.value?.toDate(),
      byweekday: byweekday.value?.length > 0 ? byweekday.value : undefined,
      bymonth: bymonth.value?.length > 0 ? bymonth.value : undefined,
    });
  });

  function getDuration() {
    return dayjs
      .duration(dtStart.dayjs.value.diff(dtEnd.dayjs.value))
      .humanize();
  }

  const $body = computed(() => ({
    tourId: tourId.value,
    // TODO: add missing props
    dtstart: dtStart.dayjs.value?.unix().toString(),
    dtend: dtEnd.dayjs.value?.unix().toString(),
    tzid: tzid.value,
    freq: freq.value,
    interval: interval.value,
    byweekday: byweekday.value?.length > 0 ? byweekday.value : undefined,
    bymonth: bymonth.value?.length > 0 ? bymonth.value : undefined,
    count: count.value,
    until: until.value?.unix().toString(),
  }));

  function $fromDTO(data: components["schemas"]["ScheduleResponse"]) {
    const dtstart = dayjs.unix(Number.parseInt(data.dtstart));
    const dtend = dayjs.unix(Number.parseInt(data.dtend));
    const until = data.until
      ? dayjs.unix(Number.parseInt(data.until))
      : undefined;

    id.value = data.id;
    effectiveFrom.value = dayjs.unix(Number.parseInt(data.effectiveFrom));
    tourId.value = data.tourId;
    dtStart.date.parts.value = [dtstart.year(), dtstart.month(), dtstart.day()];
    dtStart.time.parts.value = [dtstart.format("HH"), dtstart.format("mm")];
    dtEnd.date.parts.value = [dtend.year(), dtend.month(), dtend.day()];
    dtEnd.time.parts.value = [dtend.format("HH"), dtend.format("mm")];
    freq.value = data.freq;
    interval.value = data.interval;
    byweekday.value = data.byweekday;
    bymonth.value = data.bymonth;
    count.value = data.count;
    untilDate.value = until
      ? [until.year(), until.month(), until.day()]
      : undefined;
    tzid.value = data.tzid;
  }

  function $patch(
    data?: Partial<{
      id: string;
      tourId: string;
      dtStart: Dayjs;
      dtEnd: Dayjs;
      tzid: Timezone;
      freq: Frequency;
      interval: number;
      byweekday: Array<number>;
      bymonth: Array<number>;
      count: number;
      untilDate: [number, number, number];
    }>,
  ) {
    if (data?.id) id.value = data.id;
    if (data?.tourId) tourId.value = data.tourId;
    if (data?.dtStart) dtStart.$patch(data.dtStart);
    if (data?.dtEnd) dtEnd.$patch(data.dtEnd);
    if (data?.tzid) tzid.value = data.tzid;
    if (data?.freq) freq.value = data.freq;
    if (data?.byweekday) byweekday.value = data.byweekday;
    if (data?.bymonth) bymonth.value = data.bymonth;
    if (data?.interval) interval.value = data.interval;
    if (data?.count) count.value = data.count;
    if (data?.untilDate) untilDate.value = data.untilDate;
  }

  function $reset() {
    $patch({
      id: "",
      tourId: "",
      dtStart: undefined,
      dtEnd: undefined,
      tzid: "EUROPE_BERLIN",
      freq: undefined,
      byweekday: [],
      bymonth: [],
      interval: undefined,
      count: undefined,
      untilDate: undefined,
    });
  }

  return {
    id,
    effectiveFrom,
    tourId,
    dtStart,
    dtEnd,
    tzid,
    freq,
    interval,
    byweekday,
    bymonth,
    count,
    untilDate,
    untilDateDisplayValue,
    until,
    rrule,
    getDuration,
    $body,
    $fromDTO,
    $patch,
    $reset,
  };
}

export function useScheduleDAO(): Ref<UnwrapRef<Schedule>> {
  const remote = useSchedule();
  const local = useSchedule();

  const user = useUserStore();
  const accountId = toRef(user, "accountId");

  // const _read = backend.getScheduleById({ userId }, {});
  //   const _update = backend.updateUserProfileByUserId(
  //     { userId },
  //     computed(() => ({
  //       givenName: givenName.value,
  //       familyName: familyName.value,
  //     })),
  //     {},
  //   );

  const $isFetching: ComputedRef<boolean> = computed(
    () => false, // _read.isFetching.value || _update.isFetching.value,
  );

  function $reset() {
    local.$patch(remote.$body.value);
  }

  //   async function $read() {
  //     await _read.execute(true);
  //     $patch(_read.data.value);
  //   }

  async function $create() {
    const data = await backend.createScheduleForTour(
      { accountId: accountId.value, tourId: local.tourId.value },
      local.$body.value,
    );
    remote.$patch(data);
  }

  //   async function $update() {
  //     await _update.execute(true);
  //     $patch(_update.data.value);

  //     if (reference) {
  //       reference.value.$patch(_update.data.value);
  //     }
  //   }

  watchEffect(() => local.$patch(remote.$body.value));

  return ref({
    remote,
    local,
    $isFetching,
    $reset,
    // $read,
    $create,
    // $update,
  });
}
