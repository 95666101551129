import type { InjectionKey, Ref, UnwrapRef } from "vue";
import { TourDetails } from "./entities/tour-details";
import { useOccurrence } from "./entities/occurrence";
import { useSchedule } from "./entities/schedule";
import { TourImages } from "./entities/tour-images";
import { VenueDetails } from "./entities/venue-details";
import { VenueImages } from "./entities/venue-images";

export const venueDetailsKey = Symbol() as InjectionKey<
  Ref<UnwrapRef<VenueDetails>>
>;
export const venueImagesKey = Symbol() as InjectionKey<
  Ref<UnwrapRef<VenueImages>>
>;
export const tourDetailsKey = Symbol() as InjectionKey<
  Ref<UnwrapRef<TourDetails>>
>;
export const tourImagesKey = Symbol() as InjectionKey<
  Ref<UnwrapRef<TourImages>>
>;
export const schedulesKey = Symbol() as InjectionKey<
  Ref<Array<UnwrapRef<ReturnType<typeof useSchedule>>>>
>;
export const occurrencesKey = Symbol() as InjectionKey<
  Ref<Array<UnwrapRef<ReturnType<typeof useOccurrence>>>>
>;
