
import { useRoute } from "vue-router";
import TodoLineItem from "../../../components/todo-line-item.vue";
import { inject } from "vue";
import {
  occurrencesKey,
  schedulesKey,
  tourDetailsKey,
  tourImagesKey,
} from "../../../keys";
import { computed } from "vue";

export default {
  name: "CreateOverview",
  components: {
    TodoLineItem,
  },
  setup() {
    const route = useRoute();

    const tourDetails = inject(tourDetailsKey);
    const tourImages = inject(tourImagesKey);
    const schedules = inject(schedulesKey);
    const _occurrences = inject(occurrencesKey);

    const steps = computed(() => [
      {
        title: "Title and description",
        done: Boolean(tourDetails.value.remote.effectiveFrom),
        to: {
          name: "editTourDescription",
          params: { tourId: route.params.tourId },
        },
      },
      {
        title: "Information for participants",
        done: false,
        to: {
          name: "editTourDescription",
          params: { tourId: route.params.tourId },
        },
      },
      {
        title: "Photos",
        done: Boolean(tourImages.value.remote.effectiveFrom),
        to: { name: "editTourPhotos", params: { tourId: route.params.tourId } },
      },
      {
        title: "Venue",
        done: false,
        to: {
          name: "editTourVenue",
          params: { tourId: route.params.tourId },
        },
      },
      {
        title: "Schedules and occurrences",
        done: Boolean(schedules.value.length),
        to: {
          name: "editTourOccurrences",
          params: { tourId: route.params.tourId },
        },
      },
      {
        title: "Availability",
        done: false,
        to: {
          name: "editTourAvailability",
          params: { tourId: route.params.tourId },
        },
      },
      {
        title: "Pricing",
        done: false,
        to: {
          name: "editTourPricing",
          params: { tourId: route.params.tourId },
        },
      },
    ]);

    return {
      steps,
    };
  },
};
