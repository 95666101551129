
export default {
  name: "Rating",
  props: {
    compact: {
      type: Boolean,
      default: false,
    },
  },
};
