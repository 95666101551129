
import { useVirtualList } from "@vueuse/core";

import Icon from "../components/icon/index.vue";
import Navbar from "../components/navbar.vue";
import Tour from "../components/tour.vue";

export default {
  name: "Favorites",
  components: {
    Icon,
    Navbar,
    Tour,
  },
  setup() {
    const tours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const { list, containerProps, wrapperProps } = useVirtualList(tours, {
      itemHeight: 120,
    });

    return {
      tours,
      list,
      containerProps,
      wrapperProps,
    };
  },
};
