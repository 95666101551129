
import { inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import Icon from "../../../components/icon/index.vue";
import Navbar from "../../../components/navbar.vue";
import { venueDetailsKey } from "../../../keys";

export default {
  name: "CreateDescription",
  components: {
    Icon,
    Navbar,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const venueDetails = inject(venueDetailsKey);

    async function submit() {
      await venueDetails.value.$create();
      router.push({
        name: "editVenuePhotos",
        params: { venueId: route.params.venueId },
      });
    }

    return {
      route,
      submit,
      venueDetails,
    };
  },
};
