
import Icon from "../../../components/icon/index.vue";
import Navbar from "../../../components/navbar.vue";
import PasswordInput from "../../../components/inputs/password.vue";
import OTPPopup from "../../../popups/otp.vue";
import { useConfirmDialog } from "@vueuse/core";
import { ref } from "vue";
import { computed } from "vue";

export default {
  name: "ChangePassword",
  components: {
    Icon,
    Navbar,
    PasswordInput,
    OTPPopup,
  },
  setup() {
    const form = ref({
      password: "",
    });

    const passwordReference = ref("");
    const doPasswordsMatch = computed(
      () => form.value.password === passwordReference.value,
    );

    const otpDialog = useConfirmDialog();

    return {
      form,
      passwordReference,
      doPasswordsMatch,
      otpDialog,
    };
  },
};
