import { ComputedRef, Ref, UnwrapRef, computed, ref, watchEffect } from "vue";
import { backend } from "../services";
import { components } from "@ing-tours/vue-backend-sdk/dist/openapi";

export type VenueDetails = {
  remote: ReturnType<typeof useVenueDetails>;
  local: ReturnType<typeof useVenueDetails>;
  $isFetching: ComputedRef<boolean>;
  $reset: () => void;
  $findById: () => Promise<void>;
  $create: () => Promise<void>;
};

export function useVenueDetails() {
  const accountId: Ref<string> = ref("");
  const venueId: Ref<string> = ref("");
  const effectiveFrom: Ref<string> = ref("");
  const language: Ref<string> = ref("");
  const title: Ref<string> = ref("");
  const summary: Ref<string> = ref("");
  const description: Ref<string> = ref("");

  const $body = computed(() => ({
    venueId: venueId.value,
    title: title.value,
    summary: summary.value,
    description: description.value,
  }));

  function $fromDTO(data: components["schemas"]["VenueDetailsResponse"]) {
    venueId.value = data.venueId;
    effectiveFrom.value = data.effectiveFrom;
    language.value = data.language;
    title.value = data.title;
    summary.value = data.summary;
    description.value = data.description;
  }

  function $patch(
    data?: Partial<{
      accountId: string;
      venueId: string;
      effectiveFrom: string;
      language: string;
      title: string;
      summary: string;
      description: string;
    }>,
  ) {
    if (data?.accountId) accountId.value = data.accountId;
    if (data?.venueId) venueId.value = data.venueId;
    if (data?.effectiveFrom) effectiveFrom.value = data.effectiveFrom;
    if (data?.language) language.value = data.language;
    if (data?.title) title.value = data.title;
    if (data?.summary) summary.value = data.summary;
    if (data?.description) description.value = data.description;
  }

  function $reset() {
    $patch({
      accountId: "",
      venueId: "",
      effectiveFrom: "",
      language: "",
      title: "",
      summary: "",
      description: "",
    });
  }

  return {
    accountId,
    venueId,
    effectiveFrom,
    language,
    title,
    summary,
    description,
    $body,
    $fromDTO,
    $patch,
    $reset,
  };
}

export function useVenueDetailsDAO(): Ref<UnwrapRef<VenueDetails>> {
  const remote = useVenueDetails();
  const local = useVenueDetails();

  const $isFetching: ComputedRef<boolean> = computed(
    () => false, // TODO: _create.isFetching.value,
  );

  function $reset() {
    local.$patch(remote.$body.value);
  }

  async function $findById() {
    const data = await backend.getVenueDetails({
      accountId: local.accountId.value,
      venueId: local.venueId.value,
      language: "EN",
    });
    remote.$patch(data);
  }

  async function $create() {
    const data = await backend.createVenueDetails(
      { accountId: local.accountId.value, venueId: local.venueId.value },
      local.$body.value,
    );
    remote.$patch(data);
  }

  watchEffect(() => local.$patch(remote.$body.value));

  return ref({
    remote,
    local,
    $isFetching,
    $reset,
    $findById,
    $create,
  });
}
