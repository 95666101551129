
import Icon from "../components/icon/index.vue";
import Navbar from "../components/navbar.vue";
import Tour from "../components/tour.vue";
import Rating from "../components/rating.vue";
import Calendar from "../components/calendar/index.vue";
import SelectPopup from "../popups/select.vue";
import dayjs = require("dayjs");
import { useConfirmDialog } from "@vueuse/core";

export default {
  name: "Availabilities",
  components: {
    Icon,
    Navbar,
    Calendar,
    Tour,
    Rating,
    SelectPopup,
  },
  setup() {
    const selectDialog = useConfirmDialog();
    const availabilities = [
      {
        start: dayjs("2023-10-27"),
      },
      {
        start: dayjs("2023-10-17"),
      },
      {
        start: dayjs("2023-10-20"),
      },
      {
        start: dayjs("2023-10-3"),
      },
      {
        start: dayjs("2023-11-27"),
      },
      {
        start: dayjs("2023-11-07"),
      },
    ];

    return {
      selectDialog,
      availabilities,
    };
  },
};
