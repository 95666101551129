
import LineItem from "../components/line-item.vue";
import Icon from "../components/icon/index.vue";

export default {
  name: "TodoLineItem",
  components: {
    Icon,
    LineItem,
  },
  props: {
    title: {
      type: String,
    },
    done: {
      type: Boolean,
      default: false,
    },
  },
};
