
import Icon from "../components/icon/index.vue";

export default {
  name: "LineItem",
  components: {
    Icon,
  },
  props: {
    title: {
      type: String,
    },
  },
};
