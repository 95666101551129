
import { useRoute } from "vue-router";
import Icon from "../../../components/icon/index.vue";
import Navbar from "../../../components/navbar.vue";
import { Slider, Popup } from "vant";
import { ref } from "vue";
import { useConfirmDialog } from "@vueuse/core";

export default {
  name: "CreatePricing",
  components: {
    Icon,
    Navbar,
    Slider,
    Popup,
  },
  setup() {
    const route = useRoute();
    const commissionConfirmDialog = useConfirmDialog();
    const disableCommission = ref(false);

    commissionConfirmDialog.onConfirm(() => {
      disableCommission.value = true;
    });

    const v = ref(20);

    return {
      route,
      commissionConfirmDialog,
      disableCommission,
      v,
    };
  },
};
