
import { useVirtualList } from "@vueuse/core";

import Icon from "../components/icon/index.vue";
import Navbar from "../components/navbar.vue";
import Venue from "../components/venue.vue";
import { useRouter } from "vue-router";
import { useVenuesDAO } from "../entities/venues";
import { useVenueDAO } from "../entities/venue";
import { useUserStore } from "../stores/user";

export default {
  name: "Venues",
  components: {
    Icon,
    Navbar,
    Venue,
  },
  async setup() {
    const user = useUserStore();
    const router = useRouter();
    const venue = useVenueDAO();
    const venues = useVenuesDAO();

    venue.value.local.operatorId = user.accountId;

    const { list, containerProps, wrapperProps } = useVirtualList(
      venues.venues,
      {
        itemHeight: 120,
      },
    );

    venues.$read();

    async function createVenue() {
      await venue.value.$create();
      router.push(`/editor/venues/${venue.value.remote.id}`);
    }

    return {
      createVenue,
      venues,
      list,
      containerProps,
      wrapperProps,
    };
  },
};
