
import { Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper/modules";
import { Sticky } from "vant";
import { useIntersectionObserver } from "@vueuse/core";
import { ref } from "vue";
import Image from "../components/image.vue";
import Icon from "../components/icon/index.vue";
import Navbar from "../components/navbar.vue";
import Rating from "../components/rating.vue";
import TourCard from "../components/tour.vue";
import { useTourDAO } from "../entities/tour";
import { useRoute } from "vue-router";

export default {
  name: "Tour",
  components: {
    Image,
    Icon,
    Navbar,
    Rating,
    Sticky,
    Swiper,
    SwiperSlide,
    TourCard,
  },
  setup() {
    const route = useRoute();
    const tour = useTourDAO();
    const slider = ref(null);
    const showSolidNavbar = ref(false);

    useIntersectionObserver(slider, ([{ isIntersecting }]) => {
      showSolidNavbar.value = !isIntersecting;
    });

    tour.value.$read(route.params.tourId as string);

    return {
      tour,
      slider,
      Pagination,
      Virtual,
      showSolidNavbar,
    };
  },
};
