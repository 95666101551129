import { defineStore } from "pinia";
import { Ref, computed, ref } from "vue";
import { backend } from "../services";

export const useUserStore = defineStore("user", () => {
  const id: Ref<string> = ref("");
  const accountId: Ref<string> = ref("");
  const type: Ref<"OPERATOR" | "TOURIST" | "AFFILIATE"> = ref("TOURIST");

  function $reset() {
    id.value = "";
    accountId.value = "";
    type.value = "TOURIST";
  }

  const isLoggedIn = computed(() => Boolean(id.value));

  backend.on("logoutResponse", $reset);

  return { isLoggedIn, id, accountId, type, $reset };
});
