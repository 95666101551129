
import { Ref } from "vue";
import { ref, watch, toRefs } from "vue";
import { Slider } from "vant";
import { computed } from "vue";

const passwordRegex = [
  { regex: /.{8,}/ }, // min 8 letters,
  { regex: /[0-9]/ }, // numbers from 0 - 9
  { regex: /[a-z]/ }, // letters from a - z (lowercase)
  { regex: /[A-Z]/ }, // letters from A-Z (uppercase),
  { regex: /[^A-Za-z0-9]/ }, // special characters
] as const;

const passwordStrengthLabels = [
  "Weak",
  "Weak",
  "Weak",
  "Okay",
  "Good",
  "Great",
] as const;

const passwordStrengthColors = [
  "oklch(var(--er))",
  "oklch(var(--er))",
  "oklch(var(--er))",
  "oklch(var(--wa))",
  "oklch(var(--su))",
  "oklch(var(--su))",
] as const;

export default {
  name: "PasswordInput",
  props: {
    modelValue: {
      type: String,
    },
    showPasswordStrength: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue"],
  components: {
    Slider,
  },
  setup(props, ctx) {
    const { modelValue, showPasswordStrength } = toRefs(props);
    const value = ref("");
    watch(modelValue, (v) => {
      if (v !== value.value) {
        value.value = v;
      }
    });
    watch(value, (v) => {
      ctx.emit("update:modelValue", v);

      if (showPasswordStrength.value) {
        passwordStrength.value = passwordRegex.reduce((strength, rule) => {
          return strength + Number(rule.regex.test(v));
        }, 0);
        passwordStrengthLabel.value =
          passwordStrengthLabels[passwordStrength.value];
        passwordStrengthColor.value =
          passwordStrengthColors[passwordStrength.value];
      }
    });

    const showPassword = ref(false);
    const inputType = computed(() =>
      showPassword.value ? "text" : "password",
    );
    const passwordMaxStrength = passwordRegex.length;
    const passwordStrength = ref(0);
    const passwordStrengthLabel: Ref<(typeof passwordStrengthLabels)[number]> =
      ref(passwordStrengthLabels[passwordStrength.value]);
    const passwordStrengthColor: Ref<(typeof passwordStrengthColors)[number]> =
      ref(passwordStrengthColors[passwordStrength.value]);

    const togglePasswordVisibility = () => {
      showPassword.value = !showPassword.value;
    };

    return {
      value,
      showPassword,
      inputType,
      passwordMaxStrength,
      passwordStrength,
      passwordStrengthLabel,
      passwordStrengthColor,
      togglePasswordVisibility,
    };
  },
};
