
import { toRef, watch } from "vue";
import { useSwiper } from "swiper/vue";

export default {
  name: "SwiperControl",
  props: {
    selected: {
      type: Number,
      default: undefined,
      validator(value) {
        return typeof value === "number" && Number.isInteger(value);
      },
    },
  },
  setup(props) {
    const swiper = useSwiper();
    const selected = toRef(props, "selected");

    watch(selected, (newSelected) => {
      swiper.value.slideTo(newSelected);
    });

    return {
      swiper,
    };
  },
};
