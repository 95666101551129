import { ComputedRef, Ref, UnwrapRef, computed, ref, watchEffect } from "vue";
import { backend } from "../services";
import { AgeGroup } from "../constants/AgeGroup";

export type Availability = {
  remote: ReturnType<typeof useAvailability>;
  local: ReturnType<typeof useAvailability>;
  $isFetching: ComputedRef<boolean>;
  $reset: () => void;
  $create: () => Promise<void>;
};

export function useAvailability() {
  const accountId: Ref<string> = ref("");
  const tourId: Ref<string> = ref("");
  const id: Ref<string> = ref("");
  const effectiveFrom: Ref<string> = ref("");
  const maxGroupSize: Ref<number> = ref(10);
  const isAvailableTo: Ref<Array<AgeGroup>> = ref([
    "INFANTS",
    "CHILDREN",
    "TEENS",
    "ADULTS",
    "SENIORS",
  ]);
  const countToGroupSize: Ref<Array<AgeGroup>> = ref([
    "INFANTS",
    "CHILDREN",
    "TEENS",
    "ADULTS",
    "SENIORS",
  ]);

  const $body = computed(() => ({
    tourId: tourId.value,
    maxGroupSize: maxGroupSize.value,
    isAvailableTo: isAvailableTo.value,
    countToGroupSize: countToGroupSize.value,
  }));

  function $patch(
    data?: Partial<{
      id: string;
      tourId: string;
      accountId: string;
      effectiveFrom: string;
      maxGroupSize: number;
      isAvailableTo: Array<AgeGroup>;
      countToGroupSize: Array<AgeGroup>;
    }>,
  ) {
    if (data?.id) id.value = data.id;
    if (data?.tourId) tourId.value = data.tourId;
    if (data?.accountId) accountId.value = data.accountId;
    if (data?.effectiveFrom) effectiveFrom.value = data.effectiveFrom;
    if (data?.maxGroupSize) maxGroupSize.value = data.maxGroupSize;
    if (data?.isAvailableTo) isAvailableTo.value = data.isAvailableTo;
    if (data?.countToGroupSize) countToGroupSize.value = data.countToGroupSize;
  }

  function $reset() {
    $patch({
      id: "",
      tourId: "",
      accountId: "",
      effectiveFrom: "",
      maxGroupSize: 10,
      isAvailableTo: ["INFANTS", "CHILDREN", "TEENS", "ADULTS", "SENIORS"],
      countToGroupSize: ["INFANTS", "CHILDREN", "TEENS", "ADULTS", "SENIORS"],
    });
  }

  return {
    id,
    tourId,
    accountId,
    effectiveFrom,
    maxGroupSize,
    isAvailableTo,
    countToGroupSize,
    $body,
    $patch,
    $reset,
  };
}

export function useAvailabilityDAO(): Ref<UnwrapRef<Availability>> {
  const remote = useAvailability();
  const local = useAvailability();

  const $isFetching: ComputedRef<boolean> = computed(
    () => false, // TODO: _read.isFetching.value || _update.isFetching.value,
  );

  function $reset() {
    local.$patch(remote.$body.value);
  }

  async function $create() {
    const data = await backend.createAvailability(
      { accountId: local.accountId.value, tourId: local.tourId.value },
      local.$body.value,
    );
    remote.$patch(data);
  }

  watchEffect(() => local.$patch(remote.$body.value));

  return ref({
    remote,
    local,
    $isFetching,
    $reset,
    $create,
  });
}
