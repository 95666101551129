
export default {
  name: "Marker",
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
  },
};
