
import { useRoute } from "vue-router";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperControl from "../../../components/swiper-control.vue";
import Icon from "../../../components/icon/index.vue";
import Navbar from "../../../components/navbar.vue";
import { inject, ref } from "vue";
import { occurrencesKey, schedulesKey } from "../../../keys";

export default {
  name: "ManageOccurrences",
  components: {
    Icon,
    Navbar,
    Swiper,
    SwiperSlide,
    SwiperControl,
  },
  setup() {
    const route = useRoute();
    const swiper = ref(null);
    const tabs = ["SCHEDULES", "OCCURRENCES", "CALENDAR"] as const;
    const activeTab = ref(0);
    const schedules = inject(schedulesKey);
    const occurrences = inject(occurrencesKey);

    return {
      route,
      swiper,
      tabs,
      activeTab,
      schedules,
      occurrences,
    };
  },
};
