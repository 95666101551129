
import Icon from "../components/icon/index.vue";
import Navbar from "../components/navbar.vue";

export default {
  name: "FAQ",
  components: {
    Icon,
    Navbar,
  },
  setup() {
    const faqs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    return {
      faqs,
    };
  },
};
