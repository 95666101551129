import { ComputedRef, Ref, UnwrapRef, computed, ref, watchEffect } from "vue";
import { backend } from "../services";
import { components } from "@ing-tours/vue-backend-sdk/dist/openapi";

export type VenueImages = {
  remote: ReturnType<typeof useVenueImages>;
  local: ReturnType<typeof useVenueImages>;
  $isFetching: ComputedRef<boolean>;
  $reset: () => void;
  $read: () => Promise<void>;
  $update: () => Promise<void>;
};

export function useVenueImages() {
  const accountId: Ref<string> = ref("");
  const venueId: Ref<string> = ref("");
  const effectiveFrom: Ref<string> = ref("");
  const imageIds = ref<Array<string>>([]);

  const $body = computed(() => ({
    venueId: venueId.value,
    imageIds: imageIds.value,
  }));

  function $fromDTO(data: components["schemas"]["VenueImagesResponse"]) {
    venueId.value = data.venueId;
    effectiveFrom.value = data.effectiveFrom;
    imageIds.value = data.imageIds;
  }

  function $patch(
    data?: Partial<{
      accountId: string;
      venueId: string;
      effectiveFrom: string;
      imageIds: Array<string>;
    }>,
  ) {
    if (data?.accountId) accountId.value = data.accountId;
    if (data?.venueId) venueId.value = data.venueId;
    if (data?.effectiveFrom) effectiveFrom.value = data.effectiveFrom;
    if (data?.imageIds) imageIds.value = data.imageIds;
  }

  function $reset() {
    $patch({
      accountId: "",
      venueId: "",
      effectiveFrom: "",
      imageIds: [],
    });
  }

  return {
    accountId,
    venueId,
    effectiveFrom,
    imageIds,
    $body,
    $fromDTO,
    $patch,
    $reset,
  };
}

export function useVenueImagesDAO(): Ref<UnwrapRef<VenueImages>> {
  const remote = useVenueImages();
  const local = useVenueImages();

  const $isFetching: ComputedRef<boolean> = computed(
    () => false, // TODO: _update.isFetching.value,
  );

  function $reset() {
    local.$patch(remote.$body.value);
  }

  async function $read() {
    const data = await backend.getVenueImages({ venueId: local.venueId.value });
    remote.$patch(data);
  }

  async function $update() {
    const data = await backend.updateVenueImages(
      { accountId: local.accountId.value, venueId: local.venueId.value },
      local.$body.value,
    );
    remote.$patch(data);
  }

  watchEffect(() => local.$patch(remote.$body.value));

  return ref({
    remote,
    local,
    $isFetching,
    $reset,
    $read,
    $update,
  });
}
