
import { useRoute, useRouter } from "vue-router";
import Icon from "../../../components/icon/index.vue";
import Navbar from "../../../components/navbar.vue";
import { useConfirmDialog } from "@vueuse/core";
import dayjs from "dayjs";
import objectSupport from "dayjs/plugin/objectSupport";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import CalendarPopup from "../../../popups/calendar.vue";
import TimePopup from "../../../popups/time.vue";
import { useScheduleDAO } from "../../../entities/schedule";
import { TIMEZONES } from "../../../constants/Timezone";
import { FREQUENCY } from "../../../constants/Frequency";
import { inject } from "vue";
import { occurrencesKey, schedulesKey } from "../../../keys";

dayjs.extend(objectSupport);
dayjs.extend(LocalizedFormat);

export default {
  name: "CreateOccurrence",
  components: {
    Icon,
    Navbar,
    CalendarPopup,
    TimePopup,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const schedules = inject(schedulesKey);
    const occurrences = inject(occurrencesKey);
    const schedule = useScheduleDAO();
    schedule.value.local.tourId = route.params.tourId as string;

    const firstDayOfWeek = dayjs().localeData().firstDayOfWeek();
    const weekdays = dayjs.weekdaysShort();
    const months = dayjs.monthsShort();
    const timezones = Object.keys(TIMEZONES);
    const frequencies = Object.keys(FREQUENCY);

    const fromDatePopup = useConfirmDialog();
    fromDatePopup.onConfirm((selected) => {
      schedule.value.local.dtStart.date.parts = selected.selectedFrom.value;
    });

    const fromTimePopup = useConfirmDialog();
    fromTimePopup.onConfirm((time) => {
      schedule.value.local.dtStart.time.parts = time;
    });

    const toDatePopup = useConfirmDialog();
    toDatePopup.onConfirm((selected) => {
      schedule.value.local.dtEnd.date.parts = selected.selectedFrom.value;
    });

    const toTimePopup = useConfirmDialog();
    toTimePopup.onConfirm((time) => {
      schedule.value.local.dtEnd.time.parts = time;
    });

    const untilDatePopup = useConfirmDialog();
    untilDatePopup.onConfirm((selected) => {
      schedule.value.local.untilDate = selected.selectedFrom.value;
    });

    async function submit() {
      await schedule.value.$create();
      router.push({
        name: "editTourOccurrences",
        params: { tourId: route.params.tourId },
      });
    }

    return {
      route,
      schedule,
      firstDayOfWeek,
      weekdays,
      months,
      timezones,
      frequencies,
      fromDatePopup,
      fromTimePopup,
      toDatePopup,
      toTimePopup,
      untilDatePopup,
      submit,
    };
  },
};
