
import { computed } from "vue";
import * as dayjs from "dayjs";
import Rating from "./rating.vue";

export default {
  name: "Booking",
  components: {
    Rating,
  },
  props: {
    userType: {
      type: String,
      required: true,
      validator(value: string) {
        return ["TOURIST", "OPERATOR"].includes(value);
      },
    },
    acceptedAt: {
      type: dayjs.Dayjs,
    },
    declinedAt: {
      type: dayjs.Dayjs,
    },
    withdrawnAt: {
      type: dayjs.Dayjs,
    },
  },
  emits: ["decline", "accept", "withdraw"],
  setup() {
    return {};
  },
};
