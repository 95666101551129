
import { useConfirmDialog, useFileDialog } from "@vueuse/core";
import Navbar from "../components/navbar.vue";
import LineItem from "../components/line-item.vue";
import { Popup, showNotify } from "vant";
import Icon from "../components/icon/index.vue";
import { toRef } from "vue";
import { backend } from "../services";
import { useUserStore } from "../stores/user";
import { useRouter } from "vue-router";
import { useUploadDAO } from "../entities/upload";
import { useImageDAO } from "../entities/image";
import { useUserProfileStore } from "../stores/user-profile";

export default {
  name: "Profile",
  components: {
    Icon,
    Navbar,
    Popup,
    LineItem,
  },
  async setup() {
    const user = useUserStore();
    const { userProfile } = useUserProfileStore();
    const router = useRouter();
    const photo: ReturnType<typeof useUploadDAO> = useUploadDAO();

    const { files, open, reset, onChange } = useFileDialog({
      accept: "image/*",
    });

    onChange((files) => {
      if (files) {
        photo.value.local.file = files[0];
        photo.value.$create();
      }
    });

    const logout = backend.logout;

    const deleteAccountDialog = useConfirmDialog();
    const logOutDialog = useConfirmDialog();

    deleteAccountDialog.onConfirm(() =>
      showNotify({ type: "success", message: "Account deleted" }),
    );
    logOutDialog.onConfirm(() => {
      showNotify({ type: "success", message: "Logged out" });
      router.push("/login");
    });

    return {
      userProfile,
      deleteAccountDialog,
      logOutDialog,
      photo,
      open,
      logout,
    };
  },
};
