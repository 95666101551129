
import { Sticky } from "vant";

export default {
  name: "Navbar",
  components: {
    Sticky,
  },
  props: {
    title: {
      type: String,
    },
    solid: {
      type: Boolean,
      default: false,
    },
  },
  setup() {},
};
