
import Icon from "../components/icon/index.vue";
import Navbar from "../components/navbar.vue";
import LineItem from "../components/line-item.vue";
import { Popup, Slider } from "vant";
import { ref } from "vue";

export default {
  name: "Commission",
  components: {
    Icon,
    Navbar,
    Popup,
    Slider,
    LineItem,
  },
  setup() {
    const commission = ref(20);
    const disableCommission = ref(false);

    return {
      commission,
      disableCommission,
    };
  },
};
