
import Icon from "./icon/index.vue";

export default {
  name: "SearchResult",
  components: {
    Icon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
  },
};
