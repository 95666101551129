
import { Ref, ref, toRef, watch } from "vue";
import { useFileDialog } from "@vueuse/core";
import { useSortable } from "@vueuse/integrations/useSortable";
import Icon from "./icon/index.vue";
import { useUploadDAO } from "../entities/upload";
import { useImageDAO } from "../entities/image";
import Image from "../components/image.vue";

export default {
  name: "PhotoManager",
  components: {
    Icon,
    Image,
  },
  props: {
    modelValue: {
      type: Array<string>,
    },
    accountId: {
      type: String,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  setup(props, ctx) {
    const modelValue = toRef(props, "modelValue");
    const photoEl = ref<HTMLElement | null>(null);
    const images: Ref<
      Array<ReturnType<typeof useImageDAO> | ReturnType<typeof useUploadDAO>>
    > = ref(
      modelValue.value.map((imageId) => {
        console.log("test");
        const upload = useImageDAO();
        upload.value.remote.$patch({ id: imageId });
        return upload;
      }),
    );

    /* watch(
      modelValue,
      (newModelValue) => {
        images.value.push(
          ...newModelValue.map((imageId) => {
            const upload = useImageDAO();
            upload.value.remote.$patch({ id: imageId });
            return upload;
          }),
        );
      },
      { immediate: true },
    ); */

    watch(
      images,
      (newImages) => {
        ctx.emit(
          "update:modelValue",
          newImages.map((image) => image.value.remote.id),
        );
      },
      { deep: true },
    );

    const { files, open, reset, onChange } = useFileDialog({
      accept: "image/*",
    });

    onChange(async (files) => {
      if (files) {
        for (const file of files) {
          const upload = useUploadDAO();
          const image = useImageDAO();

          upload.value.local.$patch({
            accountId: props.accountId,
            file: file,
          });

          images.value.push(upload);

          await upload.value.$create();

          image.value.local.$patch({
            accountId: upload.value.local.accountId,
            uploadId: upload.value.local.id,
          });

          await image.value.$create();
        }
      }
    });

    const sortable = useSortable(photoEl, images, {
      animation: 150,
    });

    return {
      photoEl,
      images,
      files,
      open,
      reset,
    };
  },
};
