
import Navbar from "../../../components/navbar.vue";
import Icon from "../../../components/icon/index.vue";

export default {
  name: "Login",
  components: {
    Navbar,
    Icon,
  },
  setup() {
    return {};
  },
};
