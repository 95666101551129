import { ComputedRef, Ref, UnwrapRef, computed, ref, watchEffect } from "vue";
import { backend } from "../services";

export type Upload = {
  type: "upload";
  remote: ReturnType<typeof useUpload>;
  local: ReturnType<typeof useUpload>;
  $isFetching: ComputedRef<boolean>;
  $reset: () => void;
  $create: () => Promise<void>;
};

export function useUpload() {
  const id: Ref<string> = ref("");
  const accountId: Ref<string> = ref("");
  const url: Ref<string> = ref("");
  const file: Ref<File> = ref(null);
  const uploadProgress: Ref<number> = ref(0);
  const isUploading: Ref<boolean> = ref(false);
  const objectUrl = computed(() => {
    return file.value ? URL.createObjectURL(file.value) : undefined;
  });

  const $body = computed(() => ({
    id: id.value,
    accountId: accountId.value,
    url: url.value,
    file: file.value,
  }));

  function upload() {
    isUploading.value = true;
    uploadProgress.value = 0;

    const xhr = new XMLHttpRequest();
    const upload = new Promise<void>((resolve, reject) => {
      xhr.addEventListener("load", () => console.log("load"));
      xhr.addEventListener("loadend", () => {
        console.log("loadend");
        resolve();
      });
      xhr.addEventListener("error", () => {
        console.error("error");
        reject();
      });
      xhr.addEventListener("abort", () => {
        console.warn("abort");
        reject();
      });
    });

    xhr.upload.addEventListener("progress", (e) => {
      console.log("progress", e);
      if (e.lengthComputable) {
        uploadProgress.value = (e.loaded / e.total) * 100;

        if (e.loaded === e.total) {
          isUploading.value = false;
        }
      }
    });

    xhr.open("PUT", url.value, true);
    xhr.setRequestHeader("Content-Type", "application/octet-stream");
    xhr.send(file.value);

    return upload;
  }

  function $reset() {
    $patch({
      id: "",
      accountId: "",
      url: "",
      file: null,
    });
  }

  function $patch(
    data?: Partial<{
      id: string;
      accountId: string;
      url: string;
      file: File;
    }>,
  ) {
    if (data?.id) id.value = data.id;
    if (data?.accountId) accountId.value = data.accountId;
    if (data?.url) url.value = data.url;
    if (data?.file) file.value = data.file;
  }

  return {
    id,
    accountId,
    url,
    file,
    uploadProgress,
    isUploading,
    objectUrl,
    $body,
    upload,
    $patch,
    $reset,
  };
}

export function useUploadDAO(): Ref<UnwrapRef<Upload>> {
  const remote = useUpload();
  const local = useUpload();

  const $isFetching: ComputedRef<boolean> = computed(
    () => false, // TODO: _create.isFetching.value,
  );

  function $reset() {
    local.$patch(remote.$body.value);
  }

  async function $create() {
    const data = await backend.createUpload({
      accountId: local.accountId.value,
    });
    remote.$patch(data);
    local.$patch(data);
    await local.upload();
  }

  watchEffect(() => local.$patch(remote.$body.value));

  return ref({
    type: "upload" as const,
    remote,
    local,
    $isFetching,
    $reset,
    $create,
  });
}
