
import { useRoute } from "vue-router";
import TodoLineItem from "../../../components/todo-line-item.vue";
import { inject } from "vue";
import { venueDetailsKey, venueImagesKey } from "../../../keys";
import { computed } from "vue";

export default {
  name: "CreateOverview",
  components: {
    TodoLineItem,
  },
  setup() {
    const route = useRoute();

    const venueDetails = inject(venueDetailsKey);
    const venueImages = inject(venueImagesKey);

    const steps = computed(() => [
      {
        title: "Title and description",
        done: Boolean(venueDetails.value.remote.effectiveFrom),
        to: {
          name: "editVenueDescription",
          params: { venueId: route.params.venueId },
        },
      },
      {
        title: "Photos",
        done: Boolean(venueImages.value.remote.effectiveFrom),
        to: {
          name: "editVenuePhotos",
          params: { venueId: route.params.venueId },
        },
      },
      {
        title: "Address",
        done: false,
        to: {
          name: "editVenueAddress",
          params: { venueId: route.params.venueId },
        },
      },
    ]);

    return {
      steps,
    };
  },
};
