import { NavLink } from "./nav-link";

export const links = [
  new NavLink({
    label: "Tours",
    route: "tours",
    icon: "tour",
  }),
  new NavLink({
    label: "Venues",
    route: "venues",
    icon: "home",
  }),
  new NavLink({
    label: "Bookings",
    route: "bookings",
    icon: "booking",
  }),
  new NavLink({
    label: "Profile",
    route: "profile",
    icon: "user",
  }),
];
