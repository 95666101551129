import dayjs, { Dayjs } from "dayjs";
import { computed } from "vue";
import { useDate } from "./date";
import { useTime } from "./time";

export function useDateTime() {
  const date = useDate();
  const time = useTime();

  const _dayjs = computed(() => {
    if (!date.parts.value || !time.parts.value) return undefined;

    return dayjs({
      year: date.parts.value[0],
      month: date.parts.value[1],
      day: date.parts.value[2],
      hour: time.parts.value[0],
      minute: time.parts.value[1],
      second: 0,
    });
  });

  function $patch(d?: Dayjs) {
    date.$patch(d);
    time.$patch(d);
  }

  return {
    date,
    time,
    dayjs: _dayjs,
    $patch,
  };
}
