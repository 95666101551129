import { NavLink } from "./nav-link";

export const links = [
  new NavLink({
    label: "Explore",
    route: "explore",
    icon: "explore",
  }),
  new NavLink({
    label: "Bookings",
    route: "bookings",
    icon: "booking",
  }),
  new NavLink({
    label: "Favorites",
    route: "favorites",
    icon: "favorite",
  }),
  new NavLink({
    label: "Profile",
    route: "profile",
    icon: "user",
  }),
];
