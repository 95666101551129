import { defineStore } from "pinia";
import { backend } from "../services";
import { useUserProfileDAO } from "../entities/user-profile";
import { useUserStore } from "../stores/user";
import { watchEffect } from "vue";

export const useUserProfileStore = defineStore("userProfile", () => {
  const user = useUserStore();
  const userProfile = useUserProfileDAO();

  function $reset() {
    userProfile.value.$reset();
  }

  watchEffect(() => {
    if (user.isLoggedIn) {
      userProfile.value.$read(user.id);
    }
  });

  backend.on("logoutResponse", $reset);

  return { userProfile, $reset };
});
