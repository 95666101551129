
import { computed, ref, toRefs } from "vue";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import localeData from "dayjs/plugin/localeData";
import objectSupport from "dayjs/plugin/objectSupport";

import WeekdayHeader from "./weekday-header.vue";

dayjs.extend(isBetween);
dayjs.extend(localeData);
dayjs.extend(objectSupport);

export default {
  name: "CalendarMonth",
  emits: ["select"],
  props: {
    displayYear: {
      type: Number,
      required: true,
    },
    displayMonth: {
      type: Number,
      required: true,
    },
    availabilities: {
      type: Array<Number>,
    },
    selectedFrom: {
      type: Array<number>,
    },
    selectedTo: {
      type: Array<number>,
    },
    displayWeekdayHeader: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    WeekdayHeader,
  },
  setup(props) {
    const { selectedFrom, selectedTo } = toRefs(props);

    const weekdays = dayjs.weekdaysShort(); // can also be .weekdays() or .weekdaysMin()
    const displayMonth = ref(
      dayjs({ year: props.displayYear, month: props.displayMonth }),
    );
    const offset = computed(() => {
      return displayMonth.value.startOf("month").day();
    });
    const nDaysInMonth = computed(() => {
      return displayMonth.value.daysInMonth();
    });
    const nDaysInDisplayRange = computed(() => {
      return Math.ceil((offset.value + nDaysInMonth.value) / 7) * 7;
    });
    const displayRange = computed(() => {
      const min = offset.value;
      const max = min + nDaysInMonth.value;
      return new Array(nDaysInDisplayRange.value).fill(null).map((_, i) => {
        if (i >= min && i < max) return i - offset.value + 1;
        return null;
      });
    });

    function isSelected(index: number): boolean {
      if (
        selectedFrom.value &&
        selectedFrom.value[0] === props.displayYear &&
        selectedFrom.value[1] === props.displayMonth &&
        selectedFrom.value[2] === index
      ) {
        return true;
      }

      if (
        selectedTo.value &&
        selectedTo.value[0] === props.displayYear &&
        selectedTo.value[1] === props.displayMonth &&
        selectedTo.value[2] === index
      ) {
        return true;
      }

      return false;
    }

    function isWithinSelectedRange(index: number): boolean {
      if (!selectedFrom.value) return false;
      if (!selectedTo.value) return false;

      if (
        selectedFrom.value[0] > props.displayYear ||
        (selectedFrom.value[0] === props.displayYear &&
          selectedFrom.value[1] > props.displayMonth) ||
        (selectedFrom.value[0] === props.displayYear &&
          selectedFrom.value[1] === props.displayMonth &&
          selectedFrom.value[2] > index)
      ) {
        return false;
      }

      if (
        selectedTo.value[0] < props.displayYear ||
        (selectedTo.value[0] === props.displayYear &&
          selectedTo.value[1] < props.displayMonth) ||
        (selectedTo.value[0] === props.displayYear &&
          selectedTo.value[1] === props.displayMonth &&
          selectedTo.value[2] < index)
      ) {
        return false;
      }

      return true;
    }

    function isAvailable(index: number): boolean {
      if (!props.availabilities) return false;
      return props.availabilities.some((d) => d === index);
    }

    return {
      weekdays,
      displayRange,
      isAvailable,
      isSelected,
      isWithinSelectedRange,
    };
  },
};
