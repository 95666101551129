
import { Swiper, SwiperSlide } from "swiper/vue";
import { FloatingPanel } from "vant";
import MapMarker from "../components/map/marker.vue";

import SearchPopup from "../popups/search.vue";
import FilterPopup from "../popups/filter.vue";
import Icon from "../components/icon/index.vue";
import Map from "../components/map/index.vue";
import Tour from "../components/tour.vue";
import SwiperControl from "../components/swiper-control.vue";

export default {
  name: "Explore",
  components: {
    SearchPopup,
    FilterPopup,
    Icon,
    Map,
    Tour,
    SwiperControl,
    Swiper,
    SwiperSlide,
    FloatingPanel,
    MapMarker,
  },
  setup() {
    return {};
  },
};
