
import { useConfirmDialog } from "@vueuse/core";
import { Swiper, SwiperSlide } from "swiper/vue";
import * as dayjs from "dayjs";
import SwiperControl from "../components/swiper-control.vue";
import Navbar from "../components/navbar.vue";
import Booking from "../components/booking.vue";
import { ref } from "vue";
import { Popup, showNotify } from "vant";
import { useUserStore } from "../stores/user";

export default {
  name: "Bookings",
  components: {
    Navbar,
    Booking,
    Swiper,
    SwiperSlide,
    SwiperControl,
    Popup,
  },
  setup() {
    const swiper = ref(null);
    const tabs = ["PENDING", "UPCOMING", "PAST"] as const;
    const activeTab = ref(0);
    const pendingBookings: { acceptedAt?: string; declinedAt?: string }[] = [
      {},
      {},
      {},
    ];
    const upcomingBookings: {
      acceptedAt?: dayjs.Dayjs;
      declinedAt?: dayjs.Dayjs;
    }[] = [
      {
        acceptedAt: dayjs("2023-10-27"),
      },
      {
        acceptedAt: dayjs("2023-10-27"),
      },
      {
        acceptedAt: dayjs("2023-10-27"),
      },
      {
        declinedAt: dayjs("2023-10-27"),
      },
      {
        declinedAt: dayjs("2023-10-27"),
      },
      {
        declinedAt: dayjs("2023-10-27"),
      },
    ];

    const declineBookingDialog = useConfirmDialog();
    const acceptBookingDialog = useConfirmDialog();
    const withdrawBookingDialog = useConfirmDialog();

    declineBookingDialog.onConfirm(() =>
      showNotify({ type: "success", message: "Booking declined" }),
    );
    acceptBookingDialog.onConfirm(() =>
      showNotify({ type: "success", message: "Booking accepted" }),
    );
    withdrawBookingDialog.onConfirm(() =>
      showNotify({ type: "success", message: "Booking request cancelled" }),
    );

    return {
      declineBookingDialog,
      acceptBookingDialog,
      withdrawBookingDialog,
      tabs,
      activeTab,
      swiper,
      pendingBookings,
      upcomingBookings,
      pastBookings: upcomingBookings,
      user: useUserStore(),
    };
  },
};
