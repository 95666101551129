import { ComputedRef, Ref, ref } from "vue";
import dayjs, { Dayjs } from "dayjs";
import objectSupport from "dayjs/plugin/objectSupport";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { Timezone } from "../constants/Timezone";
import { components } from "@ing-tours/vue-backend-sdk/dist/openapi";
import { useDateTime } from "../composables/date-time";

dayjs.extend(objectSupport);
dayjs.extend(LocalizedFormat);
dayjs.extend(duration);
dayjs.extend(relativeTime);

export type Occurrence = {
  remote: ReturnType<typeof useOccurrence>;
  local: ReturnType<typeof useOccurrence>;
  $isFetching: ComputedRef<boolean>;
  $reset: () => void;
  //   $read: () => Promise<void>;
  $create: () => Promise<void>;
  //   $update: () => Promise<void>;
};

export function useOccurrence() {
  const scheduleId: Ref<string> = ref("");
  const dtStart = useDateTime();
  const dtEnd = useDateTime();
  const tzid: Ref<Timezone> = ref("EUROPE_BERLIN");

  function $fromDTO(data: components["schemas"]["OccurrenceResponse"]) {
    const dtstart = dayjs.unix(Number.parseInt(data.start));
    const dtend = dayjs.unix(Number.parseInt(data.end));
    scheduleId.value = data.scheduleId;
    dtStart.date.parts.value = [dtstart.year(), dtstart.month(), dtstart.day()];
    dtStart.time.parts.value = [dtstart.format("HH"), dtstart.format("mm")];
    dtEnd.date.parts.value = [dtend.year(), dtend.month(), dtend.day()];
    dtEnd.time.parts.value = [dtend.format("HH"), dtend.format("mm")];
    tzid.value = data.timezone;
  }

  function getDuration() {
    return dayjs
      .duration(dtStart.dayjs.value.diff(dtEnd.dayjs.value))
      .humanize();
  }

  function $patch(
    data?: Partial<{
      scheduleId: string;
      dtStart: Dayjs;
      dtEnd: Dayjs;
      tzid: Timezone;
    }>,
  ) {
    if (data?.scheduleId) scheduleId.value = data.scheduleId;
    if (data?.dtStart) dtStart.$patch(data.dtStart);
    if (data?.dtEnd) dtEnd.$patch(data.dtEnd);
    if (data?.tzid) tzid.value = data.tzid;
  }

  function $reset() {
    $patch({
      scheduleId: "",
      dtStart: undefined,
      dtEnd: undefined,
      tzid: "EUROPE_BERLIN",
    });
  }

  return {
    scheduleId,
    dtStart,
    dtEnd,
    tzid,
    getDuration,
    $fromDTO,
    $patch,
    $reset,
  };
}
