
import { Popup } from "vant";

import Calendar from "../components/calendar/index.vue";
import WeekdayHeader from "../components/calendar/weekday-header.vue";
import PopupHeader from "../components/popup/header.vue";
import { PropType, Ref, ref } from "vue";

export default {
  name: "CalendarPopup",
  components: {
    Calendar,
    Popup,
    PopupHeader,
    WeekdayHeader,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    position: {
      type: String as PropType<"top" | "bottom">,
      default: "bottom",
    },
    cover: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["cancel", "apply"],
  setup() {
    const selectedFrom: Ref<[number, number, number] | undefined> = ref();
    const selectedTo: Ref<[number, number, number] | undefined> = ref();

    const select = (selected) => {
      selectedFrom.value = selected.selectedFrom;
      selectedTo.value = selected.selectedTo;
    };

    return {
      select,
      selectedFrom,
      selectedTo,
    };
  },
};
