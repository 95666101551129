
import { computed } from "vue";
import Icon from "../../components/icon/index.vue";
import * as operator from "./operator";
import * as tourist from "./tourist";

export default {
  name: "BottomNav",
  components: {
    Icon,
  },
  props: {
    userType: {
      type: String,
      required: true,
      validator(value: string) {
        return ["TOURIST", "OPERATOR"].includes(value);
      },
    },
  },
  setup(props) {
    const links = computed(() => {
      return props.userType === "TOURIST" ? tourist.links : operator.links;
    });

    return {
      links,
    };
  },
};
