
import Navbar from "../components/navbar.vue";
import LineItem from "../components/line-item.vue";
import { Popup } from "vant";
import Icon from "../components/icon/index.vue";
import dayjs from "dayjs";
import { ref } from "vue";

export default {
  name: "Invoices",
  components: {
    Icon,
    Navbar,
    Popup,
    LineItem,
  },
  setup() {
    const month = ref(dayjs());

    return {
      month,
    };
  },
};
