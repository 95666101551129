
import { computed, defineAsyncComponent, toRef } from "vue";

export default {
  name: "Icon",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const id = toRef(props.id);

    const icon = computed(() => {
      switch (id.value) {
        case "add":
          return defineAsyncComponent(() => import("./icons/add.vue"));
        case "apple":
          return defineAsyncComponent(() => import("./icons/apple.vue"));
        case "arrow-left":
          return defineAsyncComponent(() => import("./icons/arrow-left.vue"));
        case "arrow-right-s":
          return defineAsyncComponent(
            () => import("./icons/arrow-right-s.vue"),
          );
        case "booking":
          return defineAsyncComponent(() => import("./icons/booking.vue"));
        case "checkbox":
          return defineAsyncComponent(() => import("./icons/checkbox.vue"));
        case "close":
          return defineAsyncComponent(() => import("./icons/close.vue"));
        case "draggable":
          return defineAsyncComponent(() => import("./icons/draggable.vue"));
        case "edit":
          return defineAsyncComponent(() => import("./icons/edit.vue"));
        case "explore":
          return defineAsyncComponent(() => import("./icons/explore.vue"));
        case "favorite":
          return defineAsyncComponent(() => import("./icons/favorite.vue"));
        case "filter":
          return defineAsyncComponent(() => import("./icons/filter.vue"));
        case "focus":
          return defineAsyncComponent(() => import("./icons/focus.vue"));
        case "google":
          return defineAsyncComponent(() => import("./icons/google.vue"));
        case "goto":
          return defineAsyncComponent(() => import("./icons/goto.vue"));
        case "home":
          return defineAsyncComponent(() => import("./icons/home.vue"));
        case "instagram":
          return defineAsyncComponent(() => import("./icons/instagram.vue"));
        case "list":
          return defineAsyncComponent(() => import("./icons/list.vue"));
        case "map":
          return defineAsyncComponent(() => import("./icons/map.vue"));
        case "pinterest":
          return defineAsyncComponent(() => import("./icons/pinterest.vue"));
        case "search":
          return defineAsyncComponent(() => import("./icons/search.vue"));
        case "subtract":
          return defineAsyncComponent(() => import("./icons/subtract.vue"));
        case "tiktok":
          return defineAsyncComponent(() => import("./icons/tiktok.vue"));
        case "tour":
          return defineAsyncComponent(() => import("./icons/tour.vue"));
        case "user":
          return defineAsyncComponent(() => import("./icons/user.vue"));
        case "warning":
          return defineAsyncComponent(() => import("./icons/warning.vue"));
      }
    });

    return {
      icon,
    };
  },
};
