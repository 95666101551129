
import { Popup } from "vant";

import GeoSearchPopup from "../popups/geo-search.vue";
import CalendarPopup from "../popups/calendar.vue";
import ParticipantsPopup, { Participants } from "../popups/participants.vue";
import PopupHeader from "../components/popup/header.vue";
import { ref } from "vue";

export default {
  name: "FilterPopup",
  components: {
    Popup,
    PopupHeader,
  },
  emits: ["cancel", "select"],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const filter = ref<{}>();

    return {
      filter,
    };
  },
};
