
import { onUnmounted, provide } from "vue";
import Icon from "../../../components/icon/index.vue";
import Navbar from "../../../components/navbar.vue";
import { useRoute } from "vue-router";
import { venueDetailsKey, venueImagesKey } from "../../../keys";
import { useUserStore } from "../../../stores/user";
import { useVenueImagesDAO } from "../../../entities/venue-images";
import { useVenueDetailsDAO } from "../../../entities/venue-details";

export default {
  name: "VenueWizard",
  components: {
    Icon,
    Navbar,
  },
  async setup() {
    const route = useRoute();
    const user = useUserStore();
    const venueDetails = useVenueDetailsDAO();
    const venueImages = useVenueImagesDAO();

    venueDetails.value.local.$patch({
      accountId: user.accountId,
      venueId: route.params.venueId as string,
    });

    venueImages.value.local.$patch({
      accountId: user.accountId,
      venueId: route.params.venueId as string,
    });

    onUnmounted(() => {
      venueDetails.value.local.$reset();
    });

    provide(venueDetailsKey, venueDetails);
    provide(venueImagesKey, venueImages);

    await venueDetails.value.$findById().catch(() => {});
    await venueImages.value.$read().catch(() => {});

    return {};
  },
};
