import { Component } from "vue";

export class NavLink {
  public label: string;
  public route: string;
  public icon: string;

  constructor({
    label,
    route,
    icon,
  }: {
    label: string;
    route: string;
    icon: string;
  }) {
    this.label = label;
    this.route = route;
    this.icon = icon;
  }
}
